<template>
  <div class="reporting management-page">
    <view-report-modal
      v-model="showViewModal"
      :details="showViewDetails"
      @keydown.esc="closeTransaction"
      @open-refund="showRefundModal = true"
    />

    <base-modal v-model="showRefundModal">
      <refund-modal
        :transaction="transactionDetails"
        @update="handleRefundSuccess"
      />
    </base-modal>

    <div class="title" style="color: #383838; font-size: 24px">Reporting</div>
    <div class="mt-3 mb-2 position-relative">
      <!-- Add margin to separate from elements above and below -->
    </div>
    <div v-if="hasMetaFieldSearch && metaFieldSearchTerm" class="row">
      <div class="col-md-9">
        <div class="form-group">
          <label for="searchParameter">Enter Search Parameter:</label>
          <input id="searchParameter" v-model="searchParameter" type="text" class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <base-button
            class="ms-auto search-payee-email"
            :disabled="runSearchDisabled.value"
            @click="runSearch"
          >
            <span class="fz-16 fw-500 vertical-align-middle"> + </span>
            <span class="vertical-align-middle">Run Search</span>
          </base-button>
        </div>
      </div>
    </div>
    <div v-if="hasMetaFieldSearch && metaFieldSearchTerm" class="mt-3 mb-2 position-relative">

      <!-- Add margin to separate from elements above and below -->
    </div>
    <base-spinner v-if="loadingList" class="loader" />
    <AgGrid
      ref="agRef"
      :apiurl="apiUrl"
      :columns="[]"
      :define-defs="columnDefs"
      :side-bar="true"
      :status-bar="statusBar"
      :agg-func="undefined/*aggFunc*/"
      :excel-styles="excelStyles"
      group-panel=""
      :counter="true"
      :excel-options="{ fileName: 'Transaction Report.xlsx' }"
      height="84vh"
      total-column-name="Gross Amount"
      :get-api-data-on-ready-enabled="false"
      grid-config-key="reporting-page"
      @filter-changed="filterModified"
      @row2-selected="selected => selected?.length ? openTransaction(selected[0].data) : null"
    ></AgGrid>
  </div>
</template>

<script setup>
import {ref, computed, onBeforeMount, onMounted} from 'vue';
import AgGrid from '@/components/AgGrid.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import ViewReportModal from '@/components/reporting/ViewReportModal.vue';
import RefundModal from '@/components/reporting/RefundModal.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import {
  PAYMENT_METHOD_CARD, PAYMENT_METHOD_CHECK,
  PAYMENT_TYPE_ACH, PAYMENT_TYPE_ACH_READABLE, PAYMENT_TYPE_ACH_REFUND, PAYMENT_TYPE_ACH_REFUND_READABLE,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_CARD_READABLE,
  PERMISSION_PRIMITIVES
} from '@/config/constants';
import { useStore } from 'vuex';
import http from "@/services/http";
import BaseButton from "@/components/base/BaseButton.vue";
import { PRIMARY_CLIENT_ID } from "@/config/constants";
import { computedAsync } from '@vueuse/core';
import { shouldShowRefund } from '@/pages/ReportingPageUtils';

const store = useStore();
const showLocation = computed(() => store.getters.merchantHasPermissions);
const hasPermissions = computed(() => store.getters.merchantHasPermissions);
const userHasCreditCardRefundPermission = computed(() => store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.TRANSACTION_REFUND));
const userHasAchRefundPermission = computed(() => store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.TRANSACTION_CREDIT_ACH_REFUND));
const userHasCreditCardRefundPermission_to_merchants = computed(() => {
  return new Set(store.state.user
    ?.account
    ?.memberships
    ?.filter(m => m?.role?.
      granted_permissions
      ?.map(e => e.name)
      ?.includes(PERMISSION_PRIMITIVES.TRANSACTION_REFUND)
    ).map(e => e.merchant.id) || []);
});
const userHasAchRefundPermission_to_merchants = computed(() => {
  return new Set(store.state.user
    ?.account
    ?.memberships
    ?.filter(m => m?.role?.
      granted_permissions
      ?.map(e => e.name)
      ?.includes(PERMISSION_PRIMITIVES.TRANSACTION_CREDIT_ACH_REFUND)
    ).map(e => e.merchant.id) || []);
});
const userHasRefundPermission = computed(() => {
  return (
    userHasCreditCardRefundPermission.value || userHasAchRefundPermission.value
  );
});
const loadingList = ref(false);
const hasMetaFieldSearch = computed(() => store.state.user?.account?.default_client?.merchant?.configuration?.meta_field_search);
const metaFieldSearchTerm = computed(() => store.state.user?.account?.default_client?.merchant?.configuration?.meta_field_search_term);
const searchParameter = ref('');
const showViewModal = ref(false);
const showViewDetails = ref([]);
/**
 * @type {import("vue").Ref<null | ReportTransaction>}
 */
const transactionDetails = ref(null);
const showRefundModal = ref(false);
const excelStyles = [
  {
    id: 'dateType',
    dataType: 'dateTime',
    numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' }
  }
];
const start = ref(
  new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split('T')[0]
);
const end = ref(new Date().toISOString().split('T')[0]);

/**
 * @typedef {Object} ReportTransaction
 * @property {string} id - Transaction ID
 * @property {string} client_id - Client ID
 * @property {boolean} is_deleted - Whether this record is soft deleted
 * @property {string} created_at - Date of creation
 * @property {string} updated_at - Date of last update
 * @property {'settle' | 'debit' | 'blind_refund' | 'CAPTURE' | 'AUTH_CAPTURE' | 'authorize' | 'AUTH_ONLY' | 'void' | 'charge' | 'refund' | 'credit'} action - Type of action taken
 * @property {'settle' | 'debit' | 'blind_refund' | 'authorize' | 'void' | 'charge' | 'apple_pay' | 'refund'} action_mode - Category of the action
 * @property {'settle' | 'terminal_charge' | 'debit' | 'adjustment' | 'collected' | 'terminal_blind_refund' | 'cash' | 'void' | 'cc' | 'payout' | 'charge' | 'refund' | 'credit' | 'batch' | 'subscription' | 'terminal' | 'terminal_void' | 'authorize'} type - Type of transaction
 * @property {'refund' | 'cash' | 'ach' | 'card' | 'terminal'} payment_method_used - Type of payment
 * @property {boolean} is_pass_to_consumer
 * @property {boolean} request_status - Whether the request(s) to the processor was successful
 * @property {boolean} payment_status - Whether the payment was successfully processed overall
 * @property {string} currency - Type of currency used
 * @property {number | string} amount - Amount of money processed in the transaction (decimal(20, 2))
 * @property {number} fee
 * @property {number} tip
 * @property {number} surcharge
 * @property {number} platform_fee
 * @property {number} split_fee
 * @property {number} convenience_fee
 * @property {number} initial_amount
 * @property {Object.<string, string>} request_headers - Headers of original client request which initiated the transaction
 * @property {string} pro_pay_status
 * @property {Settlement} settlement
 * @property {TransactionData} data
 */

/**
 * @typedef {Object} Settlement
 * @property {string} date
 * @property {string} type - Type of settlement data source (for parsing settlement data)
 * @property {number | string} amount - Amount which was transferred as part of settlement
 * @property {Object.<string, string>} raw_data - Raw data from the data source
 */

/**
 * @typedef {Object} TransactionData
 * @property {string} id - ID of the transaction metadata
 * @property {boolean} is_deleted - Whether this record is soft deleted
 * @property {string} created_at - Date of creation
 * @property {string} updated_at - Date of last update
 * @property {string} processor - Type of third party API
 * @property {number} latency - Number of seconds waiting for response
 * @property {TransactionApiResponse} api_response - Object which was returned from the API
 * @property {Object} third_party_api_response - Object which was returned from the API from the third party API
 */

/**
 * @typedef {{
 *   amount: string
 *   convenience_fee: string
 *   details: Record<string, any>
 *   fee: string
 *   initial_amount: string
 *   ip: string
 *   method: string
 *   platform_fee: string
 *   status: boolean
 *   surcharge: string
 *   transaction_id: string
 *   meta: {
 *     customFields?: Record<string, any>
 *   } & Record<string, any>
 *   original_transaction_id: string
 *   original_transaction: {ReportTransaction}
 * }} TransactionApiResponse
 */

/**
 * this is the type of item in array returned by the API
 * @typedef {[string, ReportTransaction, string, string]} ReportingTuple
 */
const apiUrl = ref(`/api/v1/report?start=${start.value}&end=${end.value}`);
/*
// no longer used since `externalFilterChanged` commented out
const filterValue = ref('year to date'.replaceAll(/ /g, ''));
*/

const defaultClientId = computed(() => store.getters.defaultClientId);

/**
 * @type {import("vue").Ref<AgGrid>}
 */
const agRef = ref(null);

const handleRefundSuccess = () => {
  showRefundModal.value = false;
  transactionDetails.value = null;
};

const statusBar = {};

onMounted(async () => {
  await runSearch();
});

/*
const filterOptions = {
  5: {
    values: [
      'cc',
      'charge',
      'terminal',
      'void',
      'settle',
      'refund',
      'subscription',
      'debit',
      'credit'
    ],
    filterType: 'set'
  }
};
*/

const filterModified = (model) => {
  console.log('REPORTING: filterModified function called with model: ', model);
  const dates = Object.values(model).filter(Boolean).filter(v => v.dateFrom || v.dateTo).pop()
  console.log('REPORTING: dates extracted from model: ', dates);
  start.value = dates?.dateFrom?.split(' ')[0];
  end.value = dates?.dateTo?.split(' ')[0];
  console.log('REPORTING: start and end values set: ', start.value, end.value);
  // todo make timestamps
  if (start.value && end.value) {
    apiUrl.value = `/api/v1/report?start=${start.value}&end=${end.value}`;
    if (agRef.value)
      agRef.value.refreshAPI(`/api/v1/report?start=${start.value}&end=${end.value}`);
    else
      console.error('no <AgGrid /> ref');
  }
};

const collectAllMetaFields = (transactions) => {
  if (store.state?.user?.account?.default_client?.merchant?.configuration?.customizations?.dynamic_meta_in_reporting_disabled)
    return []
  const allMetaKeys = new Set();  // Use a Set to store unique keys

  // Iterate through all transactions
  transactions.forEach(transaction => {
    const meta = transaction?.[1]?.data?.api_response?.meta || {};  // Extract the meta object

    if (typeof meta === 'string') return;
    // Add all keys of this meta object to the Set
    Object.keys(meta).forEach(key => {
      allMetaKeys.add(key);  // Add the key to the Set
    });
  });

  return Array.from(allMetaKeys);  // Convert the Set back to an array
};

const collectAllCustomFields = (transactions) => {
  let clientsSet = new Set(transactions.map(t => t?.[1]?.client_id).filter(Boolean));
  // let clients = Array.from(clientsSet);
  let fieldNames = myConfigurations.value.filter(c => clientsSet.has(c.merchant.id))
    .map(c => c?.customizations?.custom_fields?.map(c => c?.name) || [])
    .flatMap(c => c);
  let fieldNamesDeDuped = Array.from(new Set(fieldNames));
  return fieldNamesDeDuped;
  /*
  const allMetaKeys = new Set();  // Use a Set to store unique keys

  // Iterate through all transactions
  transactions.forEach(transaction => {
    // Extract the meta object
    const customFields = transaction?.[1]?.data?.api_response?.meta?.customFields || {};

    // Add all keys of this customFields to the Set
    Object.keys(customFields).forEach(key => {
      allMetaKeys.add(key);  // Add the key to the Set
    });
  });

  return Array.from(allMetaKeys);  // Convert the Set back to an array
  */
};

/**
 * @typedef {{
 *   id: string
 *   merchant: {id: string}
 *   customizations: Record<string, *>
 * }} ReportingPageMyConfiguration
 */

/**
 * @type {import("vue").ComputedRef<Array<ReportingPageMyConfiguration>>}
 */
const myConfigurations = computedAsync(async () => {
  let response = await http.get("/api/client/my-configurations");
  return response.data.message;
}, []);

/**
 * @type {import("vue").ComputedRef<Record<string, ReportingPageMyConfiguration>>}
 */
const myConfigurationsByMerchant = computed(() => {
  return myConfigurations.value.reduce((acc, next) => {
    acc[next.merchant.id] = next;
    return acc;
  }, {});
})

const runSearchDisabled = ref(false);
async function runSearch() {
  loadingList.value = true;
  // runSearchDisabled.value = true;
  const param = searchParameter.value;

  try {
    let theApiUrl = '';
    if (param) {
      theApiUrl = `/api/v1/report?start=${start.value}&end=${end.value}&search=${searchParameter.value}=${param}`;
    } else {
      theApiUrl = `/api/v1/report?start=${start.value}&end=${end.value}`;
    }

    // don't worry about meta columns if we're on the TPP default client
    // because that would take absolutely forever and a day to get loaded
    if (defaultClientId.value === PRIMARY_CLIENT_ID) {
      return;
    }

    // Fetch data (assuming you use axios or a similar library)
    const response = await http.get(theApiUrl);
    const transactions = response.data.message;  // Assuming the data comes back as an array of transactions

    if (transactions.length > 0) {

      // Get Meta Fields
      const allMetaKeys = collectAllMetaFields(transactions);
      const metaColumns = createMetaColumns(allMetaKeys);  // Create dynamic columns from meta

      let metaValues = {
        headerName: 'Meta',
        children: metaColumns
      }

      metaColumns.push({
        headerName: 'Meta ID',
        colId: 'Meta ID',
        filter: 'agTextColumnFilter',
        /**
         * @param {{data: ReportingTuple}} p
         */
        valueGetter: (p) => {
          console.log('insideTheNewValueGetter')
          if (!p.data) return '';
          let meta = p.data[1]?.data?.api_response?.meta;
          return typeof meta === 'string' ? meta : '';
        },
      })

      const hasMetaColumns = Boolean(Object.keys(metaValues).length !== 0);

      // Get Custom Fields
      let customValues = {};
      const customFields = collectAllCustomFields(transactions);
      const customFieldsColumns = createCustomFieldColumns(customFields);
      if (customFieldsColumns.length > 0) {
        customValues = {
          headerName: 'Custom Fields',
          children: customFieldsColumns
        }
      }
      const hasCustomValues = Boolean(Object.keys(customValues).length !== 0);

      columnDefsExtra.value = [];

      if (hasMetaColumns) {
        columnDefsExtra.value.push(metaValues)
      }

      if (hasCustomValues) {
        columnDefsExtra.value.push(customValues);
      }
      // Update ag-grid rows and columns
      if (agRef.value) {
        agRef.value.setTheRowData(transactions);  // Set the row data
        agRef.value.setTheColumnDefs(columnDefs.value);  // Update column definitions
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loadingList.value = false;
    // runSearchDisabled.value = false;
  }
}

// Function to dynamically create meta columns
const createMetaColumns = (metaKeys) => {
  const columns = [];

  // Loop over the meta keys and create columns for each
  metaKeys.forEach(key => {
    if (!['email', 'firstName', 'lastName', 'customFields'].includes(key)) {  // Exclude certain fields
      columns.push({
        headerName: key.charAt(0).toUpperCase() + key.slice(1),  // Capitalize key for better readability
        filter: 'agTextColumnFilter',
        valueGetter: (p) => p.data?.[1]?.data?.api_response?.meta?.[key] || ''  // Get the value dynamically
      });
    }
  });

  return columns;
};

const createCustomFieldColumns = (customFieldKeys) => {
  const columns = [];

  // Loop over the meta keys and create columns for each
  customFieldKeys.forEach(key => {
    columns.push({
      headerName: key.charAt(0).toUpperCase() + key.slice(1),  // Capitalize key for better readability
      filter: 'agTextColumnFilter',
      // Get the value dynamically
      /**
       * @param {{data: ReportingTuple}} p
       */
      valueGetter: (p) => {
        let value = p.data?.[1]?.data?.api_response?.meta?.customFields?.[key] || '';

        // already checked in collect phase:
        // let configurationExists = myConfigurationsByMerchant.value[p.data?.[1]?.client_id].customizations.custom_fields.includes(key);

        /**
         * @type {StoreUserMembership}
         */
        let membership = store.getters.membershipsByMerchant[p.data?.[1]?.client_id];
        let permissionExists = key in (membership?.role?.custom_field_access || {});

        if (permissionExists)
          return value;
        return '';
      }
    });
  });

  return columns;
};

/*
const externalFilterChanged = () => {
  const currentDate = new Date();
  const today = new Date().toISOString().split('T')[0];
  switch (filterValue.value) {
    case 'past7days':
      start.value = new Date(currentDate.getTime() - 8 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    case 'past30days':
      start.value = new Date(currentDate.getTime() - 31 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    case 'yeartodate':
      start.value = new Date(currentDate.getFullYear(), 0, 1)
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    case 'lastmonth':
      start.value = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      )
        .toISOString()
        .split('T')[0];
      end.value = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
        .toISOString()
        .split('T')[0];
      break;
    case 'monthtodate':
      start.value = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      )
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    default:
      start.value = new Date(currentDate.getTime() - 8 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      end.value = today;
  }
  apiUrl.value = `/api/v1/report?&start=${start.value}&end=${end.value}`;
  agRef.value.refreshAPI(`/api/v1/report?&start=${start.value}&end=${end.value}`);
};
*/

/**
 * @typedef {import("ag-grid-community").ColDef | import("ag-grid-community").ColGroupDef} AgColumn
 */

/**
 * @type {import("vue").Ref<Array<AgColumn>>}
 */
const columnDefsExtra = ref([]);

/**
 * @type {import("vue").Ref<Array<AgColumn>>}
 */
// noinspection JSUnusedGlobalSymbols
const columnDefs = computed(() => [
  {
    headerName: `Transaction Details`,
    children: [
      {
        headerName: 'ID',
        colId: 'ID',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.id;
        },
      },
      {
        headerName: 'Created At',
        colId: 'Created At',
        cellClass: 'dateType',
        valueGetter: (p) => {
          if (!p.data) return '';
          return new Date(p.data[0]).toISOString();
        },
        valueFormatter: (p) => {
          if (!p.data) return '';
          let x = new Date(Date.parse(p.data[0]));
          return x.toLocaleString('en-US');
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: ['inRange'],
          inRangeInclusive: true
        },
        sort: 'desc'
      },
      {
        headerName: 'Parent',
        colId: 'Parent',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[3];
        }
      },
      {
        headerName: 'DBA Name',
        colId: 'DBA Name',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[2];
        }
      },
      ...((!showLocation.value) ? [] : [{
        headerName: 'Location',
        colId: 'Location',
        filter: 'agSetColumnFilter',
        /**
         * @param {{data: ReportingTuple}} p
         */
        valueGetter: (p) => {
          return p?.data?.[1]?.data?.api_response.meta?.["customFields"]?.["Clinic Location"];
        },
      }]),
      {
        headerName: 'Payment Method',
        colId: 'Payment Method',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          const theType = p.data[1]?.type;
          if (theType === PAYMENT_TYPE_CARD) {
            return PAYMENT_METHOD_CARD;
          }
          if (theType === PAYMENT_TYPE_ACH) {
            return PAYMENT_METHOD_CHECK;
          }
          if (theType === PAYMENT_TYPE_ACH_REFUND) {
            return PAYMENT_METHOD_CHECK;
          }
          return theType;
        }
      },
      {
        headerName: 'Transaction Type',
        colId: 'Transaction Type',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          const theAction = p.data[1]?.action;
          if (theAction === PAYMENT_TYPE_ACH) {
            return PAYMENT_TYPE_ACH_READABLE;
          }
          if (theAction === PAYMENT_TYPE_ACH_REFUND) {
            return PAYMENT_TYPE_ACH_REFUND_READABLE;
          }
          return theAction;
        }
      },
      {
        headerName: 'Original Transaction',
        colId: 'Original Transaction',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          return p.data?.[1]?.data?.api_response?.original_transaction_id || '';
        },
        cellRenderer: (p) => {
          if (!p.data) return '';
          /**
           * @type {ReportTransaction}
           */
          const txData = p.data[1];
          const oTid = txData?.data?.api_response?.original_transaction_id;
          if (!oTid) return '';
          return `<p style="font-size: 12px; text-decoration: underline;">Refund for ${oTid}</p>`;
        },
        /**
         * @param {{node: {data: ReportingTuple}}} params
         */
        onCellClicked: function (params) {
          let value = params.node.data;
          let dataIsValid = Array.isArray(value) && value.length === 4 && value[1]?.data?.api_response?.original_transaction;
          if (!dataIsValid) return;
          let txData = dataIsValid;
          try {
            txData.client_id = txData.client_id || value[1].client_id;
          } catch(e) {
            //
          }
          const shouldShow = shouldShowRefund({
            txData,
            hasPermissions,
            userHasAchRefundPermission,
            userHasAchRefundPermission_to_merchants,
            userHasCreditCardRefundPermission,
            userHasCreditCardRefundPermission_to_merchants,
          });

          if (shouldShow) {
            showViewDetails.value = [value[0], value[1].data.api_response.original_transaction, value[2], value[3]];
            showViewModal.value = true;
          }
        }
      },
      {
        headerName: 'Authorized',
        colId: 'Authorized',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.payment_status;
        },
        cellStyle: function (p) {
          if (!p.data) return '';
          // noinspection EqualityComparisonWithCoercionJS
          if (
              p.data[1]?.payment_status == false ||
              p.data[1]?.payment_status == 'false' ||
              p.data[1]?.payment_status == 'False'
          ) {
            //Here you can check the value and based on that you can change the color
            //mark police cells as red
            return {color: '#a87e71'};
          } else {
            return {color: '#86a871'};
          }
        }
      },
      {
        headerName: 'Status',
        colId: 'Status',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.status;
        },
      },
      {
        headerName: 'Last 4',
        colId: 'Last 4',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          try {
            const encodedToken = p?.data?.[1]?.data?.api_response?.token;

            // Step 1. Decode the token
            const decodedToken = atob(encodedToken);

            // Step 2: Split the decoded string using the '-' as a delimiter
            const parts = decodedToken.split('-');

            // Step 3: Access the value "3232", which is the 3rd element (index 2) in the array
            const lastFour = parts[2];

            return lastFour;
          } catch (err) {
            console.error(err);
            console.log("error occurred - see above");
            return 'n/a';
          }
        },
      },
      {
        headerName: 'Gross Amount',
        colId: 'Gross Amount',
        filter: 'agNumberColumnFilter',
        field: 'amount',
        aggFunc: 'total',
        /**
         * @param {{data: ReportingTuple}} p
         */
        valueGetter: (p) => {
          if (!p.data) return null;
          // oh my god
          const theData = p.data[1];

          const theProPayStatus = theData?.pro_pay_status || null;

          const typesToChargeProPayFeeTo = [
            'ACHInReturned', 'ACHInRejected',
          ];

          if (theProPayStatus !== null && typesToChargeProPayFeeTo.includes(theProPayStatus)) {
            return -25.00;
          }

          const theStatus = theData?.payment_status || "False";
          if (theStatus === "False") {
            return null;
          }

          const theAction = theData?.action_mode || null;
          if (theAction === null) {
            return null;
          }

          const typesToTotal = [
            "settle", "debit", "charge", "apple_pay"
          ];

          const typesToSubtract = [
            "refund", "void", "blind_refund", "reversal", "credit"
          ];

          const theAmount = parseFloat(theData?.amount) || 0.0;

          if (typesToSubtract.includes(theAction)) {
            return -theAmount;
          }

          if (!typesToTotal.includes(theAction)) {
            return null;
          }

          return theAmount;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          // do not use the value from backend, use value as computed above
          // return parseFloat(p.data[1]?.amount).toFixed(2) || 0.0;
          const value = p.colDef.valueGetter(p);
          return (parseFloat(value) || 0.00).toFixed(2);
        }
      },
      {
        headerName: 'Card Brand',
        colId: 'Card Brand',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return 'n/a';
          return p.data[1]?.card_brand;
        },
      },
      {
        headerName: 'Card Type',
        colId: 'Card Type',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return 'n/a';
          return p.data[1]?.card_type;
        },
      },
      {
        headerName: 'Settlement',
        colId: 'Settlement',
        filter: 'agNumberColumnFilter',
        valueGetter: p => {

          const theData = p.data[1];
          const theProPayStatus = theData?.pro_pay_status || null;
          const typesForProPayTransactionThatDoNotSettle = [
            'ACHInReturned', 'ACHInRejected', 'CCDebitVoided'
          ];
          if (theProPayStatus !== null && typesForProPayTransactionThatDoNotSettle.includes(theProPayStatus)) {
            return 'n/a';
          }

          const settlementAmount = p.data[1]?.settlement?.amount;
          return settlementAmount ? parseFloat(settlementAmount).toFixed(2) : 'n/a';
        }
      },
      {
        headerName: 'Settlement Date',
        colId: 'Settlement Date',
        cellClass: 'dateType',
        valueGetter: (p) => {
          if (!p.data[1]?.settlement?.date) return 'n/a';
          const theData = p.data[1];
          const theProPayStatus = theData?.pro_pay_status || null;
          const typesForProPayTransactionThatDoNotSettle = [
            'ACHInReturned', 'ACHInRejected', 'CCDebitVoided'
          ];
          if (theProPayStatus !== null && typesForProPayTransactionThatDoNotSettle.includes(theProPayStatus)) {
            return 'n/a';
          }
          return new Date(p.data[1]?.settlement?.date).toISOString().split('T')[0];
        },
        valueFormatter: (p) => {
          if (!p.data[1]?.settlement?.date) return 'n/a';
          const theData = p.data[1];
          const theProPayStatus = theData?.pro_pay_status || null;
          const typesForProPayTransactionThatDoNotSettle = [
            'ACHInReturned', 'ACHInRejected', 'CCDebitVoided'
          ];
          if (theProPayStatus !== null && typesForProPayTransactionThatDoNotSettle.includes(theProPayStatus)) {
            return 'n/a';
          }
          let x = new Date(Date.parse(p.data[1]?.settlement?.date));
          return x.toLocaleDateString('en-US');
        },
      },
    ]
  },
  ...(!store.getters.hasPermission(PERMISSION_PRIMITIVES.REPORTS_ADVANCED) ? [] : [{
    headerName: 'Advanced Transaction Details',
    children: [
      {
        headerName: 'Net Amount',
        colId: 'Net Amount',
        filter: 'agNumberColumnFilter',
        field: 'amount',
        /**
         * @param {{data: ReportingTuple}} p
         */
        valueGetter: (p) => {
          if (!p.data) return null;
          // oh my god
          const theData = p.data[1];

          const theProPayStatus = theData?.pro_pay_status || null;

          const typesToChargeProPayFeeTo = [
            'ACHInReturned', 'ACHInRejected',
          ];

          if (theProPayStatus !== null && typesToChargeProPayFeeTo.includes(theProPayStatus)) {
            return -25.00;
          }

          const theStatus = theData?.payment_status || "False";
          if (theStatus === "False") {
            return null;
          }

          const theAction = theData?.action_mode || null;
          if (theAction === null) {
            return null;
          }

          const typesToTotal = [
            "settle", "debit", "charge", "apple_pay"
          ];

          const typesToSubtract = [
            "refund", "void", "blind_refund", "reversal", "credit"
          ];

          const theFee = parseFloat(theData?.fee) || 0.0;
          const theAmount = parseFloat(theData?.amount) || 0.0;

          if (typesToSubtract.includes(theAction)) {
            return -theAmount;
          }

          if (!typesToTotal.includes(theAction)) {
            return null;
          }

          return theAmount - theFee;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          // do not use the value from backend, use value as computed above
          // return parseFloat(p.data[1]?.amount).toFixed(2) || 0.0;
          const value = p.colDef.valueGetter(p);
          return (parseFloat(value) || 0.00).toFixed(2);
        }
      },
      {
        headerName: 'Fee',
        colId: 'Fee',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.fee;
        },
      },
      {
        headerName: 'Surcharge',
        colId: 'Surcharge',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '0.00';
          return p.data[1]?.surcharge;
        },
      },
    ]
  }]),
  ...(/*store.getters.merchantHasPermissions ? [] : */[{
    headerName: 'Customer',
    children: [
      {
        headerName: 'First Name',
        colId: 'First Name',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.data?.api_response?.meta?.firstName;
        },
      },
      {
        headerName: 'Last Name',
        colId: 'Last Name',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.data?.api_response?.meta?.lastName;
        },
      },
      {
        headerName: 'Email',
        colId: 'Email',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.data?.api_response?.meta?.email;
        },
      },
      /*
      {
        headerName: 'Zip Code',
        colId: 'Zip Code',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.data?.api_response?.meta?.zipCode;
        },
      },
      */
    ]
  }]),
  ...columnDefsExtra.value,
  {
    headerName: 'Actions',
    children: [
      {
        headerName: 'Open',
        colId: 'Open',
        filter: false,
        field: 'view',
        cellRenderer: (p) => {
          if (p.data && p.data[1]?.amount)
            return `<p style="font-size: 12px; text-decoration: underline;">Open</p>`;
          return '';
        },
        /**
         * @param {{node:{data: ReportingTuple}}} params
         * @returns {string}
         */
        onCellClicked: function (params) {
          openTransaction(params.node.data);
        }
      },
      {
        headerName: 'Refund',
        colId: 'Refund',
        filter: false,
        field: 'refund',
        hide: hasPermissions.value && !userHasRefundPermission.value,
        /**
         * @param {{data: ReportingTuple}} p
         * @returns {string}
         */
        cellRenderer: function (p) {
          const returnLink = `<p style="font-size: 12px; text-decoration: underline;">Refund</p>`;
          if (!p.data) return '';
          const txData = p.data[1];
          // noinspection EqualityComparisonWithCoercionJS
          const shouldShow = shouldShowRefund({
            txData,
            hasPermissions,
            userHasAchRefundPermission,
            userHasAchRefundPermission_to_merchants,
            userHasCreditCardRefundPermission,
            userHasCreditCardRefundPermission_to_merchants
          });
          return shouldShow ? returnLink : '';
        },
        /**
         * @param {{data: ReportingTuple}} p
         * @returns {string}
         */
        onCellClicked: function (p) {
          if (!p.data) return '';
          const txData = p.data[1];
          const shouldShow = shouldShowRefund({
            txData,
            hasPermissions,
            userHasAchRefundPermission,
            userHasAchRefundPermission_to_merchants,
            userHasCreditCardRefundPermission,
            userHasCreditCardRefundPermission_to_merchants
          });

          if (shouldShow) {
            transactionDetails.value = txData;
            showRefundModal.value = true;
          }
        }
      }
    ]
  },
]);

/**
 * @param {ReportingTuple} transaction
 */
function openTransaction(transaction) {
  transactionDetails.value = transaction[1];
  showViewDetails.value = transaction;
  showViewModal.value = true;
}

function closeTransaction() {
  transactionDetails.value = null;
  showViewDetails.value = false;
  showViewModal.value = false;
  showRefundModal.value = false;
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.min.css';
.reporting {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
