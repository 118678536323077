import { createLogger, createStore } from 'vuex';
import http from '@/services/http';
import { LicenseManager } from 'ag-grid-enterprise';
import { router } from '@/router';
import { withAccessMenu } from '@/store/withAccessMenu';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-043994 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Triple Play Pay )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Triple Play Pay )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Triple Play Pay )_need_to_be_licensed___( Triple Play Pay )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 21 June 2024 )____[v2]_MTcxODkyNDQwMDAwMA==2715c856a3cb3ab5c966698c55c41fac'
);

const DEFAULT_TYPE = 'warning';
const DEFAULT_DELAY = 3000;

/**
 * @typedef {EditingUser} StoreUser
 */

/**
 * @typedef {{
 *   custom_fields?: Array<{
 *     name: string
 *     order: number
 *     type: string
 *     values?: Array<string>
 *   }>
 *   dynamic_meta_in_reporting_disabled?: boolean
 *   header_enabled?: boolean
 *   rbac_enabled?: boolean
 *   receipt_custom_customer_bottom_message_list?: string
 *   receipt_logo_s3_uri?: string
 *   save_card_option_enabled?: boolean
 * }} MerchantCustomizations
 */

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   level: 'Merchant' | 'Parent'
 *   merchant_permissions: Array<{
 *     id: string
 *     name: string
 *   }>
 *   configuration: {
 *     id: string
 *     merchant_id: string
 *     end_of_day: string
 *     enabled: string
 *     check_emaf: string
 *     auto_onboard: string
 *     pause_settlement: string
 *     skip_epx_pricing_onboarding_call: string
 *     ach: string
 *     crypto: string
 *     payment: string
 *     terminals: string
 *     payout: string
 *     limits_high_ticket: string
 *     limits_transactions: string
 *     limits_hourly_velocity: string
 *     limits_daily_number_of_devices_a_payment_can_be_made_from: string
 *     limits_daily_same_credit_card_transaction_amount: string
 *     customizations: MerchantCustomizations & Object
 *     tax_enabled: string
 *     level_two_data_enabled: string
 *     level_three_data_enabled: string
 *     meta_field_search: string
 *     meta_field_search_term: string
 *     rev_share_percentage: string
 *     rev_share_formula: string
 *   }
 * }} StoreMerchantModel
 */

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   merchant: {
 *     id: string
 *     name: string
 *   }
 *   roles_granted: Array<{
 *     id: string
 *     name: string
 *   }>
 * }} StorePermission
 */

/**
 * @typedef {{
 *   user: StoreUser
 *   permissions: string | Array<StorePermission>
 *   routerLoading: boolean
 *   allMerchantConfigs: null | Record<string, StoreMerchantModel>
 * }} StoreState
 */

/**
 * @typedef {{
 *   parent_logo: string
 *   parent_name: string
 * }} BrandedParent
 */

/**
 * @type {Store<StoreState>}
 */
const store = createStore({
  plugins: [createLogger()],
  state: {
    /**
     * @type {StoreUser}
     */
    user: {},
    isAuthenticated: false,
    viewAs: null,
    clients: [],
    clientDetails: {},
    visible: false,
    showToast: false,
    options: {
      title: null,
      text: null,
      delay: null,
      type: null,
      autohide: true,
      canClose: true
    },
    app: null,
    auth: null,
    token: null,
    claims: null,
    /**
     * @type {string | Array<StorePermission>}
     */
    permissions: 'FETCHING',
    routerLoading: 0,
    /**
     * @type {null | Record<string, StoreMerchantModel>}
     */
    allMerchantConfigs: null,
    /**
     * @type {BrandedParent | null}
     */
    brandedParent: null,
    accessMenu: null
  },
  mutations: {
    updateAccessMenu(state, value) {
      state.accessMenu = value;
    },
    setRouterLoading(state, value) {
      state.routerLoading += value ? 1 : -1;
    },
    updateUser(state, value) {
      console.log(value);
      console.log('updateUser - value is above');
      state.user = value;
    },
    isAuthenticated(state, value) {
      console.log(value);
      console.log('isAuthenticated - value is above');
      state.isAuthenticated = value;
    },
    updateToken(state, value) {
      console.log(state);
      console.log('store - update token - state is above');
      console.log(value);
      console.log('store - update token - value is above');
      state.token = value;
    },
    updateClaims(state, claims) {
      console.log(claims);
      console.log('store - update claims - value is above');
      state.claims = claims;
    },
    updatePermissions(state, permissions) {
      console.log('Updating permissions', permissions);
      state.permissions = permissions;
    },
    setViewAs(state, id) {
      state.viewAs = id;
    },
    updateClients(state, value) {
      state.clients = value;
    },
    updateClient(state, value) {
      state.clientDetails = value;
    },
    show(
      state,
      {
        title,
        text,
        delay = DEFAULT_DELAY,
        autohide = true,
        canClose = true,
        type = DEFAULT_TYPE
      }
    ) {
      state.options = {
        title,
        text,
        delay,
        autohide,
        canClose,
        type
      };

      state.showToast = true;
    },
    close(state) {
      state.options = {
        title: null,
        text: null,
        delay: null,
        type: null,
        autohide: true,
        canClose: true
      };
      state.showToast = false;
    },
    updateMcc(state, value) {
      state.mccCodes = value;
    },
    updateBrandedParent(state, value) {
      state.brandedParent = value;
    },
    notes(state, value) {
      state.visible = value;
    },
    updateAppInstance(state, value) {
      state.app = value;
    },
    updateAuthInstance(state, value) {
      state.auth = value;
    }
  },
  actions: {
    fetchAccessMenu({ commit, state }) {
      const computedAccessMenu = withAccessMenu(state, store);
      commit('updateAccessMenu', computedAccessMenu);
    },
    fetchUser({ commit }) {
      try {
        http.get('/api/user').then((res) => {
          const userResult = res.data.message;
          commit('updateUser', userResult);

          // establish other fields
          const brandedParent = {
            parent_name: userResult.parent_name,
            parent_logo: userResult.parent_logo
          }
          commit('updateBrandedParent', brandedParent);

          this.dispatch('fetchPermissions');
          this.dispatch('fetchAllMerchants');
          if (window.location.pathname.includes('/login') && res.data.status) {
            console.log('Re-Routing to app/home');

            router.push('/home');
          } else if (
            !res.data.message &&
            !window.location.pathname.includes('/login')
          ) {
            router.push('/login');
          }
        });
      } catch (e) {
        console.error(e);
        router.push('/login');
      }
    },
    fetchClients({ commit }) {
      try {
        console.log('fetch clients');

        http.get('/api/clients').then((res) => {
          commit('updateClients', res.data.message);
        });
      } catch (e) {
        console.error(e);
        router.push('/login');
      }
    },
    fetchClient({ commit }) {
      return http.get('/api/client').then((res) => {
        commit('updateClient', res.data.message);
      });
    },
    fetchMcc({ commit }) {
      http.get('/api/mcc').then((res) => {
        commit('updateMcc', res.data);
      });
    },

    fetchPermissions({ commit, state, getters }) {
      const defaultClientId = getters.defaultClientId;

      if (!defaultClientId) {
        console.error('No default client ID found for the user.');
        return;
      }

      http
        .get(`/api/rbac/${defaultClientId}/permissions`)
        .then((res) => {
          commit('updatePermissions', res.data.message);
          this.dispatch('fetchAccessMenu')
        })
        .catch((error) => {
          console.error('Error fetching permissions:', error);
          commit('updatePermissions', 'FAILED');
        });
    },

    fetchAllMerchants({ state }) {
      http.get('/api/client/my-clients').then(res => {
        let allMerchantConfigs = res.data.message.reduce((acc, next) => {
          acc[next.id] = next;
          return acc;
        }, {});
        state.allMerchantConfigs = allMerchantConfigs;
      })
    },

    checkAuthentication({ commit }) {
      http.post('/authenticated').then((res) => {
        commit('isAuthenticated', res.data.message);
      });
    },
    logout({ commit }) {
      router.push('/login');
    },
  },
  getters: {
    /**
     * @param state
     * @returns {BrandedParent}
     */
    getBrandedParent(state) {
      return state.brandedParent;
    },
    getParentId(state) {
      return state.user.parent_id;
    },
    getUser(state) {
      return state.user;
    },
    getUserEmail(state) {
      return state.user?.email;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getToken(state) {
      return state.token;
    },
    defaultClientName(state) {
      return state.user?.account?.default_client?.merchant?.name;
    },
    defaultClientId(state) {
      return state.user?.account?.default_client?.id;
    },
    defaultClientid(state) {
      return state.user?.account?.default_client?.id;
    },
    getPermissions(state) {
      return state.permissions;
    },
    /**
     * Convenience getter for if a merchant has permissions configured.
     * Defaults to strict enforcement. Returns true
     * while fetching permissions or if permissions endpoint failed.
     * @param {*} state
     * @returns
     */
    merchantHasPermissions(state) {
      // this is what it did before
      // it reads the default merchant configuration now
      const value = state.user?.account?.default_client.merchant?.configuration?.customizations?.['rbac_enabled']
      return typeof value === 'boolean' ? value : false;
    },
    isCertificationClient(state) {
      // this is what it did before
      // it reads the default merchant configuration now
      const value = state.user?.account?.default_client?.is_in_certification;
      return typeof value === 'boolean' ? value : false;
    },
    role(state) {
      if (state.viewAs) {
        return 'user';
      }
      return state.user?.user_type || '';
    },
    mccOptions(state) {
      const arr = [];

      for (const mccCode in state.mccCodes) {
        arr.push({
          text: state.mccCodes[mccCode]['description'],
          value: mccCode
        });
      }

      return arr;
    },
    clientList: (state) => state.clients,
    withAccessMenu(state) {
      return state.accessMenu;
    },
    /**
     * @returns {Record<string, StoreUserMembership>}
     */
    membershipsByMerchant: (state) => {
      return state.user?.account?.memberships?.reduce((acc, next) => {
        acc[next.merchant.id] = next;
        return acc;
      }, {});
    },
    /**
     * @returns {string[]}
     */
    grantedPermissions: (state) => {
      /**
       * @type {string[]}
       */
      let newVar = state.user?.account?.memberships?.flatMap(m => m.role?.granted_permissions?.map(p => p.name)) || [];
      return Array.from(new Set(newVar));
    },
    grantedPermissionsSet: (state, getters) => {
      return new Set(getters.grantedPermissions);
    },
    hasPermission: (state, getters) => (permission) => {
      /**
       * @type boolean
       */
      let result;
      if (!getters.merchantHasPermissions)
        result = true;
      else
        result = getters.grantedPermissionsSet.has(permission);

      console.log('hasPermission check returned', result, 'for', permission);
      return result;
    },
    grantedPermissionByMerchantId: (state) => {
      return state.user?.account?.memberships?.reduce((acc, next) => {
        acc[next.merchant.id] = next.role?.granted_permissions?.map(p => p.name) || [];
        return acc;
      }, {}) || {};
    },
    hasPermissionOnMerchant: (state, getters) => (permission, merchantId) => {
      if (!getters.merchantHasPermissions)
        return true;
      return getters.grantedPermissionByMerchantId[merchantId]?.includes(permission);
    },
    merchantSettings: (state) => {
      return state.user?.account?.default_client?.merchant?.configuration?.customizations || {};
    },
  }
});

export { store };
