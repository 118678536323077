<template>
  <div class="management-page dashboard-content">

    <!-- Page Header and Merchant Selector -->
    <div class="d-flex flex-wrap align-items-center mb-4">
      <div class="me-auto">
        <h1 class="page-title">Self-Certification</h1>
        <p class="page-description">
          Please use our <strong>Sandbox</strong> environment
          (recommended) to verify that your setup for charging and refunding transactions works correctly
          before moving to production.
        </p>
      </div>
      <!-- Eventually Add back in -->
<!--      <div class="merchant-selector">-->
<!--        <label for="merchantSelect" class="form-label">Select Merchant</label>-->
<!--        <select id="merchantSelect" v-model="selectedClientId" class="form-select" style="min-width: 200px;">-->
<!--          <option v-for="merchant in membershipsByMerchant" :key="merchant.id" :value="merchant.id">-->
<!--            {{ merchant.name }}-->
<!--          </option>-->
<!--        </select>-->
<!--      </div>-->
    </div>

    <!-- Steps to Certify -->
    <div class="certification-steps mb-4">
      <h2 class="section-title">Certification Steps</h2>
      <ul>
        <li v-if="testDateCompletion">
          <strong>Certification Complete!</strong>
        </li>
        <li>
          <strong>Run a Test Charge:</strong> In the Virtual Terminal or via our charge API, run a small test charge to simulate a real transaction.
        </li>
        <li>
          <strong>{{ testCharges }}</strong> Charges Have Been Run Thus Far
        </li>
        <li>
          <strong>Refund the Charge:</strong> After you've completed the charge, call our void API or go to the Reporting page to initiate a refund for that test transaction.
        </li>
        <li>
          <strong>{{ testRefunds }}</strong> Refunds Have Been Run Thus Far
        </li>
        <li>
          <strong>Verify in Reporting:</strong> Go to the Reporting page or call our v1/report API and ensure both the test charge and its corresponding refund appear as expected.
        </li>
        <li>
          <strong>{{ testReporting }}</strong> Reporting Views Have Been Run Thus Far
        </li>
      </ul>
    </div>

    <!-- Sandbox Link Section -->
    <div v-if="sandboxLink" class="virtual-terminal mb-4">
      <h3 class="section-title">Sandbox Environment Access</h3>
      <p class="mb-2">
        Click the button below to open the sandbox environment in a new tab. If you have any issues
        accessing the sandbox, please contact our support team.
      </p>
      <div class="row gap-2">
        <div class="col-12 col-md-3 metric-cards d-flex justify-content-center align-items-center" style="padding: 0 !important;">
          <a :href="sandboxLink" class="btn btn-primary" style="margin: 20px;" target="_blank" rel="noopener noreferrer">
            {{ sandboxLinkText }}
          </a>
        </div>
      </div>
    </div>

    <!-- After Certification -->
    <div class="post-certification">
      <h3 class="section-title">Next Steps</h3>
      <p>
        After you've successfully completed the test charge, refund, and verification in the reporting page,
        in the sandbox environment you have fully self-certified your integration. You can now:
      </p>
      <ul>
        <li v-if="!isSandboxEnvironment">Ensure production approval by completing an enrollment. <strong>Your current enrollment status is {{ currentEnrollmentStatus }}</strong></li>
        <li><a :href="partnerEnrollmentLink">Partners Enroll Here</a> and <a :href="merchantEnrollmentLink">Merchants Enroll Here</a></li>
        <li>Proceed to use the platform in a production environment (if approved).</li>
        <li>Contact our support, csm@tripleplaypay.com, if you face any issues or have any questions going forward.</li>
      </ul>
      <p>
        Thank you for taking the time to self-certify. We look forward to
        supporting your business and providing a seamless payment experience.
      </p>
    </div>

  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import http from '@/services/http';
import { useStore } from 'vuex';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import { useToast } from 'vue-toastification';

// Toast Configuration
const toast = useToast();
let toastdefault = {
  position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

const store = useStore();
const email = computed(() => store.getters.getUserEmail);
const defaultClientId = computed(() => store.getters.defaultClientId);

// Array of merchant memberships (objects with id and name)
const membershipsByMerchant = computed(() => store.getters.membershipsByMerchant);

// Permissions checks (if needed)
const userHasVirtualTerminalPermission = computed(() => {
  return store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL);
});
const hasPermissions = computed(() => store.getters.merchantHasPermissions);
const virtualTerminalPermissions = computed(() => {
  if (userHasVirtualTerminalPermission.value) {
    return true;
  } else {
    return !hasPermissions.value;
  }
});

const isSandboxEnvironment = ref(false);

// Determine if we're in the sandbox environment
// For example, if sandbox environment URLs are like: https://sandbox.example.com
// This checks if the hostname starts with "sandbox."
if (window.location.hostname.startsWith('sandbox.')) {
  isSandboxEnvironment.value = true;
}

const partnerEnrollmentLink = computed(() =>`https://tripleplaypay.com/partner-signup/${store.getters.getParentId}/${store.getters.defaultClientId}`);
const merchantEnrollmentLink = computed(() =>`https://tripleplaypay.com/enroll/${store.getters.getParentId}/${store.getters.defaultClientId}`);

const selectedClientId = ref(defaultClientId.value);
const testCharges = ref(0);
const testRefunds = ref(0);
const testReporting = ref(0);
const testDateCompletion = ref(null);
const isSubmitting = ref(false);
const sandboxLink = ref("");
const currentEnrollmentStatus = ref("");
const sandboxLinkText = ref('Sandbox Link Loading...');

const fixTheLinkPerEnvironment = (theLink) => {
  const parsedUrl = new URL(theLink);

  // Replace the origin with the current window's origin
  parsedUrl.protocol = window.location.protocol;
  parsedUrl.hostname = window.location.hostname;
  parsedUrl.port = window.location.port;

  return parsedUrl.toString();
}

const loadCertificateProgress = (clientId) => {
  isSubmitting.value = true;
  // Assuming the API accepts a clientId parameter:
  http.get(`/api/certification?id=${clientId}`)
      .then(response => {
        isSubmitting.value = false;
        if (response.status === 200 && response.data.message) {
          testCharges.value = response.data.message.charges;
          testRefunds.value = response.data.message.refunds;
          testReporting.value = response.data.message.reporting;
          testDateCompletion.value = response.data.message.date_time_completed;
        } else {
          sandboxLinkText.value = "Certification Loading Error";
        }
      })
      .catch(error => {
        isSubmitting.value = false;
        toast.error("Failed to load certificate progress");
        console.error('An error occurred while loading certification data:', error);
      });
};

const loadCurrentEnrollmentStatus = (clientId) => {
  isSubmitting.value = true;
  // Assuming the API endpoint supports clientId parameter:
  http.get(`/api/enroll?id=${clientId}`)
      .then(response => {
        isSubmitting.value = false;
        if (response.status === 200 && response.data.message) {
          currentEnrollmentStatus.value = response.data.message.application_status;
        } else {
          currentEnrollmentStatus.value = "Unknown";
        }
      })
      .catch(error => {
        isSubmitting.value = false;
        console.error('An error occurred while loading enrollment status:', error);
      });
};

const loadSandboxLink = () => {
  isSubmitting.value = true;
  const data = { email: email.value };
  http.post(`/api/sandboxlink`, data)
      .then(response => {
        if (response.status === 200 && response.data.message) {
          isSubmitting.value = false;
          sandboxLinkText.value = "Click Here"
          sandboxLink.value = response.data.message;
        } else {
          sandboxLinkText.value = "Sandbox Loading Error"
          isSubmitting.value = false;
        }
      })
      .catch(error => {
        isSubmitting.value = false;
        toast.error("Failed to load sandbox link");
        console.error('An error occurred while loading sandbox link:', error);
      });
};

// Watch for changes in selectedClientId to re-fetch data
watch(selectedClientId, (newClientId) => {
  if (newClientId) {
    loadCertificateProgress(newClientId);
    loadCurrentEnrollmentStatus(newClientId);
  }
});

onMounted(() => {
  loadSandboxLink();
  loadCertificateProgress(selectedClientId.value);
  loadCurrentEnrollmentStatus(selectedClientId.value);
});
</script>

<style scoped>
.dashboard-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.page-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.page-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.section-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.certification-steps {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.certification-steps ol {
  padding-left: 20px;
}

.metric-cards {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.virtual-terminal {
  padding: 20px;
  border-radius: 8px;
  background: #f8f9fa;
}

.post-certification {
  padding: 20px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-top: 20px;
}

.merchant-selector {
  margin-left: 20px;
}

.merchant-selector .form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
</style>