<script setup>
import { computed, ref, watch } from 'vue';
import http from '@/services/http';
import { useStore } from 'vuex';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';

/**
 * @typedef {{
 *   name: string,
 *   phone?: string,
 *   address: {
 *     address?: string,
 *     address1?: string,
 *     address2?: string,
 *     city?: string,
 *     st?: string,
 *     zip?: string,
 *   },
 *   merchant: {
 *     name: string,
 *   },
 * }} SubEntity
 */

const store = useStore();
const defaultClientId = computed(() => store.getters.defaultClientId);
const merchants = ref([]);

/**
 * @type {import('vue').Ref<SubEntity[]>}
 */
const subEntities = ref([]);
const loadingMerchants = ref(false);
const loadingSubEntities = ref(false);
const selectedMerchant = ref(null);
const loadingPageData = ref(false);
const newSubEntityName = ref('');
const isEditingSubEntity = ref(false);

// edit location
const editLocationName = ref('');
const editLocationPhone = ref('');
const editLocationAddress = ref('');
const editLocationAddressOne = ref('');
const editLocationAddressTwo = ref('');
const editLocationState = ref('');
const editLocationZip = ref('');
const editLocationCity = ref('');

async function loadMerchants() {
  loadingMerchants.value = true;
  try {
    const response = await http.get(`/api/user/apikeys`);
    merchants.value = response.data.message;
  } catch (error) {
    console.error('Error fetching merchants:', error);
  } finally {
    loadingMerchants.value = false;
  }
}

async function loadSubEntities(merchantId) {
  loadingSubEntities.value = true;
  try {
    const response = await http.get(`/api/rbac/${merchantId}/sub-entities?skipRecursion=true`);
    const subEntitiesData = response.data.message ?? [];
    subEntities.value = subEntitiesData.sort((a, b) =>
      a?.name?.localeCompare(b?.name)
    );
  } catch (error) {
    console.error('Error fetching sub-entities for merchant:', error);
  } finally {
    loadingSubEntities.value = false;
  }
}

async function loadPageData() {
  loadingPageData.value = true;
  try {
    await Promise.all([
      loadMerchants()
      //   loadSubEntities(selectedMerchant.value ? selectedMerchant.value.id : null)
    ]);
  } catch (error) {
    console.error('Failed to load page data:', error);
  } finally {
    loadingPageData.value = false;
  }
}

// onMounted(() => {
//   loadMerchants();
// });

if (defaultClientId.value) {
  loadPageData();
}

watch([() => defaultClientId.value], ([newClientId], [oldClientId]) => {
  // on first load, handles null merchant ID to update on incoming client ID
  // and refresh data

  if (newClientId && !oldClientId) {
    loadPageData();
  }
});

function selectMerchant(merchant) {
  if (merchant.id === selectedMerchant.value?.id) {
    selectedMerchant.value = null;
  } else {
    selectedMerchant.value = merchant;
    subEntities.value = [];
    loadSubEntities(merchant.id);
  }
}

const showAddSubEntityModal = ref(false);

function openAddSubEntityModal() {
  showAddSubEntityModal.value = true;
}

const isAddingSubEntity = ref(false);
const addingSubEntityError = ref('');
async function addSubEntity(subEntityName) {
  if (!selectedMerchant.value || !subEntityName) {
    console.error('No selected merchant or sub-entity name provided');
    return;
  }

  try {
    isAddingSubEntity.value = true;
    const response = await http.post(
      `/api/rbac/${selectedMerchant.value.id}/sub-entities`,
      {
        name: newSubEntityName.value
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (selectedMerchant.value.id === response.data.message.merchant_id) {
      subEntities.value.push(response.data.message);
    }
    console.log('Sub-entity added:', response.data.message);
    addingSubEntityError.value = '';
  } catch (error) {
    console.error('Error adding sub-entity:', error);

    // noinspection UnnecessaryLocalVariableJS
    /**
     * @type {import("axios").AxiosError}
     */
    let axiosError = error;
    addingSubEntityError.value = axiosError?.response?.data?.error || error;
  } finally {
    isAddingSubEntity.value = false;
  }
}
const showEditSubEntityModal = ref(false);

/**
 * @type {import('vue').Ref<SubEntity | null>}
 */
const selectedSubEntity = ref(null);

/**
 * @param {SubEntity} subEntity
 */
function openEditSubEntityModal(subEntity) {
  selectedSubEntity.value = subEntity;
  editLocationName.value = subEntity?.name;
  editLocationPhone.value = subEntity?.phone;
  editLocationAddress.value = subEntity?.address?.address;
  editLocationAddressOne.value = subEntity?.address?.address1;
  editLocationAddressTwo.value = subEntity?.address?.address2;
  editLocationState.value = subEntity?.address?.st;
  editLocationZip.value = subEntity?.address?.zip;
  editLocationCity.value = subEntity?.address?.city;
  showEditSubEntityModal.value = true;
}

async function updateSubEntity() {
  if (!selectedSubEntity.value || !selectedSubEntity.value.name) {
    console.error('No selected sub-entity or name provided');
    return;
  }

  try {
    isEditingSubEntity.value = true;
    const subEntityAddress = {
      address: editLocationAddress.value,
      address1: editLocationAddressOne.value,
      address2: editLocationAddressTwo.value,
      st: editLocationState.value,
      zip: editLocationZip.value,
      city: editLocationCity.value,
    };
    const subEntityRequest = {
      name: editLocationName.value,
      phone: editLocationPhone.value,
      address: subEntityAddress
    };
    const response = await http.patch(
      `/api/rbac/${selectedMerchant.value.id}/sub-entities/${selectedSubEntity.value.id}`,
        subEntityRequest,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const index = subEntities.value.findIndex(
      (se) => se.id === selectedSubEntity.value.id
    );
    if (index !== -1) {
      subEntities.value[index] = response.data.message;
    }
    console.log('Sub-entity updated:', response.data.message);
    showEditSubEntityModal.value = false;
  } catch (error) {
    console.error('Error updating sub-entity:', error);
  } finally {
    isEditingSubEntity.value = false;
  }
}

async function deleteSubEntity(subEntityId) {
  if (!selectedMerchant.value || !subEntityId) {
    console.error('No selected merchant or sub-entity ID provided');
    return;
  }

  try {
    isEditingSubEntity.value = true;
    const response = await http.delete(
      `/api/rbac/${selectedMerchant.value.id}/sub-entities/${subEntityId}`
      //   {
      //     params: {
      //       force: true // Assuming force delete is always true for simplicity
      //     }
      //   }
    );
    subEntities.value = subEntities.value.filter((se) => se.id !== subEntityId);
    console.log('Sub-entity deleted:', response.data.message);
  } catch (error) {
    console.error('Error deleting sub-entity:', error);
  } finally {
    isEditingSubEntity.value = false;
  }
}
</script>

<template>
  <div class="management-page">
    <div class="mb-3">
      <span class="dashboard-title">Sub-Locations</span>
    </div>
    <div v-if="loadingMerchants">
      <base-spinner />
    </div>
    <div v-else>
      <ul class="list-group" style="list-style-type: none">
        <li
          v-for="merchant in merchants"
          :key="merchant?.id"
          class="list-group-item"
          :style="{
            cursor:
              merchant?.id === selectedMerchant?.id ? 'default' : 'pointer',
            width: '100%'
          }"
          @click="selectMerchant(merchant)"
        >
          <span style="width: 100%; cursor: pointer; display: block"
            >{{ selectedMerchant?.id === merchant?.id ? '▼' : '▶' }}
            {{ merchant?.name }}</span
          >
          <div v-if="selectedMerchant?.id === merchant?.id" @click.stop="">
            <div v-if="selectedMerchant?.id === merchant?.id">
              <base-spinner v-if="loadingSubEntities" />
              <div v-else>
                <button
                  class="btn btn-success"
                  type="button"
                  @click.stop="openAddSubEntityModal"
                >
                  Add New Sub-Location
                </button>

                <ul class="list-group" style="max-width: 400px">
                  <li
                    v-for="subEntity in subEntities"
                    :key="subEntity.id"
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    {{ subEntity.name }} ({{ subEntity.merchant.name }})
                    <button
                      class="btn btn-danger"
                      @click.stop="openEditSubEntityModal(subEntity)"
                    >
                      Edit
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <base-modal
      v-model="showAddSubEntityModal"
      :title="`Add Sub-Locations to ${selectedMerchant?.name}`"
    >
      <base-spinner v-if="isAddingSubEntity" />
      <div name="default">
        <div class="d-flex mb-3">
          <input
            v-model="newSubEntityName"
            class="form-control me-2"
            placeholder="Enter sub entity name"
          />
        </div>
        <button
          class="btn btn-success"
          :disabled="!newSubEntityName"
          @click="addSubEntity"
        >
          Create Sub-Location
        </button>
        <div v-if="addingSubEntityError" class="m-3">
          <p>{{ addingSubEntityError }}</p>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            @click="showAddSubEntityModal = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </base-modal>
    <base-modal
      v-model="showEditSubEntityModal"
      :title="`Edit Sub-Location ${selectedSubEntity?.name}`"
    >
      <div name="default">
        <base-spinner v-if="isEditingSubEntity" />
        <div class="container">
          <div class="row mb-3">
            <div class="col-3"><label>Name:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationName" class="form-control me-2" placeholder="Enter new sub entity name" /></div>
          </div>
          <div class="row mb-3">
            <div class="col-3"><label>Address 1:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationAddressOne" class="form-control me-2" placeholder="Enter Address: line 1" /></div>
          </div>
          <div class="row mb-3">
            <div class="col-3"><label>Address 2:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationAddressTwo" class="form-control me-2" placeholder="Enter Address: line 2" /></div>
          </div>
          <div class="row mb-3">
            <div class="col-3"><label>Address:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationAddress" class="form-control me-2" placeholder="Enter Address: To line" /></div>
          </div>
          <div class="row mb-3">
            <div class="col-3"><label>City:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationCity" class="form-control me-2" placeholder="Enter City" /></div>
          </div>
          <div class="row mb-3">
            <div class="col-3"><label>State:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationState" class="form-control me-2" placeholder="Enter State" /></div>
          </div>
          <div class="row mb-3">
            <div class="col-3"><label>Zip:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationZip" class="form-control me-2" placeholder="Enter Zip" /></div>
          </div>
          <div class="row mb-3">
            <div class="col-3"><label>Phone:</label></div>
            <!-- eslint-disable-next-line -->
            <div class="col-9"><input v-model="editLocationPhone" class="form-control me-2" placeholder="Enter Phone" /></div>
          </div>
<!--          <div class="row mb-3">-->
<!--            &lt;!&ndash; eslint-disable-next-line &ndash;&gt;-->
<!--            <input v-model="selectedSubEntity.address.city" class="form-control me-2" placeholder="Enter City" />-->
<!--            <input v-model="selectedSubEntity.address.st" class="form-control me-2" placeholder="Enter State" />-->
<!--            <input v-model="selectedSubEntity.address.zip" class="form-control me-2" placeholder="Enter Zip" />-->
<!--          </div>-->
        </div>
        <button
          class="btn btn-primary"
          :disabled="!selectedSubEntity.name"
          @click="updateSubEntity"
        >
          Save Changes
        </button>
        <button
          class="btn btn-danger"
          style="margin-left: 10px"
          @click="deleteSubEntity(selectedSubEntity.id)"
        >
          Delete Sub-Location
        </button>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            @click="showEditSubEntityModal = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<style scoped>
.list-group-item {
  /* cursor: pointer; */
}
.dashboard-title {
  font-size: 24px;
  color: #383838;
}
</style>
