import { createWebHistory, createRouter } from 'vue-router';
import routes from '@/router/routes.js';
import { useStore } from 'vuex';
import cookieService from '@/services/cookies';

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_ROUTER_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

/**
 * Commenting out per the following: 
 * 
 * 1. Difficulty with new browser session 3rd party login
 * 2. Lack of rendering anything other than login page for link-based auth
 * 
 * Once these issues are resolved I'll add this back in.
 */
router.beforeEach(async (to, from) => {
  if (to.path === from.path)
    return;

  const store = useStore();
  store.commit('setRouterLoading', true);

  /**
   * These are views a user should ALWAYS be able to navigate from
   */
  const freeRedirectList = [
    'Login',
    'Verify Email',
    'Sso',
    'Create link'
  ];

  console.log(to);
  console.log('to');
  console.log(from);
  console.log('from');
  console.log('Inside the router - to and from above');

  // ❗️ Avoid an infinite redirect
  if (!freeRedirectList.includes(to.name)) {
    await cookieService.checkAuthentication();
    if (!store.getters.isAuthenticated) await cookieService.logoutFromRouter();
  }

  store.commit('setRouterLoading', false);
})

export { router };
