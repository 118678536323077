import useService from '@/composables/common/services';
import { ref } from 'vue';

export default function () {
    const { apiService } = useService();
    const logoSource = ref('');
    const loadingLogo = ref(false);

    const fetchLogo = (userId, merchantId, isCC) => {

        loadingLogo.value = true;

        apiService
            .get(`api/logo`)
            .then((res) => {
                loadingLogo.value = false;
                logoSource.value = `data:image/png;base64, ${res.message}`;
            })
            .catch(() => {
                loadingLogo.value = false;
            });
    };

    return {
        fetchLogo,
        logoSource,
        loadingLogo
    };
}
