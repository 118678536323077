/* eslint-disable vue/component-definition-name-casing */
/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue';
import App from './App.vue';
import { router } from '@/router';
import { store } from '@/store';
import '@/styles/variables.scss';
import '@/styles/index.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'floating-vue/dist/style.css';
import { initializeApp } from 'firebase/app';
import Toast from 'vue-toastification';
import RUM from '@/services/rum';
import ActivityService from '@/services/activity';
import 'vue-toastification/dist/index.css';
import {
  initializeAuth,
  inMemoryPersistence,
  browserPopupRedirectResolver
} from 'firebase/auth';

import VueApexCharts from 'vue3-apexcharts';
import Select2 from 'vue-select';
import 'vue-select/dist/vue-select.css';

const app = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY, // Auth / General Use
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN, // Auth with popup/redirect
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET, // Storage
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID, // Cloud Messaging
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
});

initializeAuth(app, {
  persistence: inMemoryPersistence,
  popupRedirectResolver: browserPopupRedirectResolver
});
// eslint-disable-next-line no-undef
__webpack_public_path__ = process.env.VUE_APP_BASE_ROUTER_URL;

const vueApp = createApp(App).use(router).use(store).use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 2,
  newestOnTop: true
});
vueApp.use(VueApexCharts);
vueApp.component('apexchart', VueApexCharts);
vueApp.component('Select2', Select2);

vueApp.mount('#app');

RUM();
new ActivityService();