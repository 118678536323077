<template>
  <nav class="navbar fixed-top bg-main-gradient" style="box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);">
    <div class="container-fluid">
      <a class="navbar-brand title" style="color: #fff; font-size: 19px; font-weight: 400" href="#">
        <img :src="tripleLogoUrl" alt="Logo" width="35" height="35">
        Triple
      </a>
      <span class="navbar-text justify-content-center source-serif" style="color: #fff; font-size: 19px; font-weight: 400">
        {{ brandedParent }}
    </span>
      <ul class="nav justify-content-end">
        <li class="nav-item">
          <a class="nav-link source-serif" href="#" @click="handleLogout" style="color: #fff">Logout</a>
        </li>
      </ul>
      <div class="offcanvas offcanvas-end" data-bs-backdrop="false" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Actions</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
          </ul>
          <form class="d-flex mt-3" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success" type="submit">Search</button>
          </form>
        </div>
      </div>
    </div>
  </nav>
  <div class="main-container" style="margin-top: 60px">
    <main class="d-flex" style="width: 100%; height: 100%">
      <div v-if="showSidebar" class="management-bar-container">
        <management-sidebar v-if="!isMobile" />
      </div>

      <div class="mobile-menu-holder-holder px-4 py-4">
        <div
          v-if="isMobile"
          class="header d-flex justify-content-between align-items-center rounded py-3 m-0"
          style="margin-top: 60px"
        >
          <mobile-menu
            v-if="showSidebar"
            v-model="isMenuOpened"
            :items="mobileMenuItems"
          />
        </div>
        <slot />
      </div>
    </main>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useLogout from '@/composables/auth/useLogout.js';
import useMobileDimension from '@/composables/common/mobile';
import { computed, onMounted, ref } from 'vue';
import ManagementSidebar from '@/layouts/components/management/ManagementSidebar.vue';
import MobileMenu from '@/components/home/MobileMenu.vue';
import LogoutVariant from 'vue-material-design-icons/LogoutVariant.vue';

const route = useRoute();
const { isMobile } = useMobileDimension();
const { logout } = useLogout();
const store = useStore();
const isMenuOpened = ref(false);
const showSidebar = computed(() => {
  if (!route.meta.showMenuByQuery) {
    return true;
  }

  if (route.query.showMenu === 'true') {
    return true;
  }
  return false;
});

const tripleLogoUrl = computed(() => {
  return `${window.location.protocol}//${window.location.host}/static/images/tpp-logo.png`
})

const withAccessMenu = computed(() => {
  return store.getters.withAccessMenu;
});

const mobileMenuItems = computed(() => {
  return [
    ...withAccessMenu.value,
    {
      title: 'Logout',
      callback(e) {
        e.preventDefault();
        logout();
      },
      icon: LogoutVariant
    }
  ];
});

const brandedParent = computed(() => {
  const theBrandedParent = store.getters.getBrandedParent;
  return theBrandedParent ? theBrandedParent.parent_name : "";
});

const handleLogout = () => {
  logout().then((r) => console.log(r));
  console.log('handleLogout logging after logout().then');
};

</script>

<style lang="scss" scoped>
.main-container {
  overflow: hidden;
  min-height: 100vh;
  background: var(--c-canvas);
}

.management-bar-container {
  height: 100vh;
}

.title {
  color: var(--c-text-primary);
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .title {
    color: var(--c-text-primary);
  }
}

.page-content {
  min-height: 100vh;
}

.clear-btn {
  background: var(--c-white);
  color: var(--c-text-primary);
  padding: 3px 8px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: var(--c-grey-lighten);
  }

  &:active {
    background: var(--c-grey-light);
  }
}
</style>

<style lang="scss">
.management-page {
  height: 100%;
  .table {
    // width: calc(100vw - 80px);
    height: 70vh;
  }

  .max-as-table-width {
    width: calc(100vw - 230px - 80px);
  }

  @media screen and (max-width: 768px) {
    .table,
    .max-as-table-width {
      width: calc(100vw - 50px);
    }
  }

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    .table,
    .max-as-table-width {
      width: calc(100vw - 50px);
    }
  }
}

.mobile-menu-holder-holder {
  width: calc(100% - 280px) !important;
}

@media screen and (max-width: 1024px) {
  .mobile-menu-holder-holder {
    width: 100% !important;
  }
}

.bg-main-gradient {
  background: var(--c-secondary-gradient);
}
</style>
