<template>
  <div id="app" style="height: 100%">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useStore } from 'vuex';

const store = useStore();

onMounted(() => {
  store.dispatch('fetchUser');
});
const route = useRoute();
const layout = computed(() => {
  return route.meta.layout || DefaultLayout;
});

watch(
  () => route.path,
  () => {
    document.title = route.meta.title || 'Triple';
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import '~bootstrap-icons/font/bootstrap-icons.css';
/**
  Updating styles for ag-grid

  https://github.com/ag-grid/ag-grid-customise-theme/blob/latest/src/vue/src/App.vue

@use "~ag-grid-community/styles" as ag;

@include ag.grid-styles((
    theme: triple-play-pay,
    extend-theme: quartz,

    odd-row-background-color: #f5f5f5,
    row-border-color: transparent,
    row-hover-color: rgba(128, 255, 128, 0.1),
    checkbox-checked-color: #2661ad,
    range-selection-border-color: #ff00b1,
    range-selection-background-color: #03305633
));
 */

/**
  Update styling for titles
 */
p {
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}
span {
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

h1 {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}

h2 {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}

h3 {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}

h4 {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}

h5 {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}

h6 {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}

.title {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}

.dashboard-title {
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 400;
}
</style>
