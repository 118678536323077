<template>
  <base-modal
    :model-value="show"
    class="view-modal"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <base-spinner v-if="loading" fixed />

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="mb-2">
            <status :params="statusValue" class="reject-status" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <base-tab title="Details">
            <div class="row">
              <div class="col-lg-3">
                <div>
                  <p class="title">Transaction ID</p>
                  <input :value="details[1].id" readonly class="value" />
                </div>
              </div>

              <div class="col-lg-3">
                <div>
                  <p class="title">Date</p>
                  <input :value="details[0]" readonly class="value" />
                </div>
              </div>

              <div class="col-lg-3">
                <div>
                  <p class="title">Amount</p>
                  <input :value="details[1].amount" readonly class="value" />
                </div>
              </div>

              <div class="col-lg-3">
                <div>
                  <p class="title">Merchant</p>
                  <input :value="details[2]" readonly class="value" />
                </div>
              </div>

              <!--
              <div class="col-lg-3">
                <div>
                  <p class="title">Parent</p>
                  <input :value="details[3]" readonly class="value" />
                </div>
              </div>

              <div class="col-lg-3">
                <div>
                  <p class="title">Message</p>
                  <input :value="details[1].message" readonly class="value" />
                </div>
              </div>
              -->
            </div>

            <div class="row">
              <div class="col-lg-3 my-3">
                <div>
                  <button class="btn btn-primary" @click="openReceipt">Open Receipt</button>
                </div>
              </div>
              <div v-if="shouldShowRefund2({txData: details[1]})" class="col-lg-3 my-3">
                <div>
                  <button class="btn btn-primary" @click="$emit('openRefund')">Refund</button>
                </div>
              </div>
            </div>
          </base-tab>
        </div>
      </div>

      <base-tab title="Details" class="mt-2" :initial-open="false">
        <div class="row">
          <div v-for="df in detailFields" :key="df.title" class="col-lg-3">
            <div>
              <p class="title">{{ df.title }}</p>
              <input :value="df.detailFunction(details)" readonly class="value" />
            </div>
          </div>
        </div>
      </base-tab>

      <base-tab
        v-if="store.getters.hasPermissionOnMerchant(constants.PERMISSION_PRIMITIVES.REPORTS_ADVANCED, details?.[1].client_id || details?.[1].client?.id)"
        title="Advanced Details" class="mt-2" :initial-open="false">
        <div class="row">
          <div class="col-lg-3">
            <div>
              <p class="title">Fee</p>
              <input :value="details[1].fee" readonly class="value" />
            </div>
          </div>
        </div>
      </base-tab>

      <base-tab title="Custom Fields" class="mt-2" :initial-open="false">
        <div class="row">
          <div v-for="(value, key) in details?.[1]?.data?.api_response?.meta?.customFields" :key="key" class="col-lg-3">
            <div>
              <p class="title">{{ key }}</p>
              <input :value="value" readonly class="value" />
            </div>
          </div>
        </div>
      </base-tab>

      <base-tab title="JSON" class="mt-2" :initial-open="false">
        <div class="row">
          <div class="col-lg-12">
            <vue-json-pretty :data="detailsWithoutCustomKeys" class="pretty" />
          </div>
        </div>
      </base-tab>
    </div>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/base/BaseModal.vue';
import BaseTab from '@/components/base/BaseTab.vue';
import { computed, defineProps, ref } from 'vue';
import Status from '@/components/onboarding/table/OnboardingStatus.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { shouldShowRefund2 } from '@/pages/ReportingPageUtils';
import * as constants from '@/config/constants';
import { useStore } from 'vuex';

const store = useStore();

/**
 * @typedef {{
 *   title: string
 *   detailFunction: (d: ReportingTuple) => string
 * }} _Field
 */

/**
 * @type {Array<_Field>}
 */
const detailFields = [
  {
    title: 'Location',
    detailFunction: d => d[1]?.data?.api_response.meta?.['customFields']?.['Clinic Location'],
  },
  {
    title: 'Payment Method',
    detailFunction(d) {
      let p = { data: d };
      if (!p.data) return '';
      const theType = p.data[1]?.type;
      if (theType === constants.PAYMENT_TYPE_CARD) {
        return constants.PAYMENT_METHOD_CARD;
      }
      if (theType === constants.PAYMENT_TYPE_ACH) {
        return constants.PAYMENT_METHOD_CHECK;
      }
      if (theType === constants.PAYMENT_TYPE_ACH_REFUND) {
        return constants.PAYMENT_METHOD_CHECK;
      }
      return theType;
    },
  },
  {
    title: 'Transaction Type',
    detailFunction(d) {
      let p = { data: d };
      if (!p.data) return '';

      const theAction = p.data[1]?.action;
      if (theAction === constants.PAYMENT_TYPE_ACH) {
        return constants.PAYMENT_TYPE_ACH_READABLE;
      }
      if (theAction === constants.PAYMENT_TYPE_ACH_REFUND) {
        return constants.PAYMENT_TYPE_ACH_REFUND_READABLE;
      }
      return theAction;
    },
  },
  {
    title: 'Authorized',
    detailFunction(d) {
      return d?.[1]?.payment_status;
    },
  },
  {
    title: 'Status',
    detailFunction: d => d?.[1]?.status,
  },
  {
    title: 'Last 4',
    detailFunction: d => {
      try {
        return atob(d?.[1]?.data?.api_response?.token).split('-')[2];
      } catch (e) {
        console.error('could not parse token in ViewReportModal for', d?.[1]?.id);
      }
    },
  },
  {
    title: 'Card Brand',
    detailFunction: d => d?.[1]?.card_brand,
  },
  {
    title: 'Card Type',
    detailFunction: d => d?.[1]?.card_type,
  },
  {
    title: 'Settlement',
    detailFunction: d => {
      let p = { data: d };
      const theData = p.data[1];
      const theProPayStatus = theData?.pro_pay_status || null;
      const typesForProPayTransactionThatDoNotSettle = [
        'ACHInReturned', 'ACHInRejected', 'CCDebitVoided',
      ];
      if (theProPayStatus !== null && typesForProPayTransactionThatDoNotSettle.includes(theProPayStatus)) {
        return 'n/a';
      }

      const settlementAmount = p.data[1]?.settlement?.amount;
      return settlementAmount ? parseFloat(settlementAmount).toFixed(2) : 'n/a';
    },
  },
  {
    title: 'Settlement Date',
    detailFunction: d => {
      let p = { data: d };
      if (!p.data[1]?.settlement?.date) return 'n/a';
      const theData = p.data[1];
      const theProPayStatus = theData?.pro_pay_status || null;
      const typesForProPayTransactionThatDoNotSettle = [
        'ACHInReturned', 'ACHInRejected', 'CCDebitVoided',
      ];
      if (theProPayStatus !== null && typesForProPayTransactionThatDoNotSettle.includes(theProPayStatus)) {
        return 'n/a';
      }
      let x = new Date(Date.parse(p.data[1]?.settlement?.date));
      return x.toLocaleDateString('en-US');
    },
  },
];

// @formatter:off
defineEmits(['update:modelValue', 'update-status', 'openRefund'])

  // emits: ['update:modelValue', 'update-status'],
    const p = defineProps({
    show: {
      type: Boolean,
      default: false
    },
      /**
       * @type {ReportingTuple}
       */
    details: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  })
    const selectedType = ref(null);
    const message = ref(null);

    const statusValue = computed(() => {
      return { value: p.details.status };
    });

    const openReceipt = () => {
      const receiptId = p.details[1].id;
      const baseUrl = window.location.origin;
      const receiptUrl = `${baseUrl}/receipt?id=${receiptId}`;

      window.open(receiptUrl, '_blank');
    };

    const detailsWithoutCustomKeys = computed(() => {
      const cp = p.details[1] ;

      delete cp.actions;
      delete cp.index;

      return cp;
    });
// @formatter:on
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  color: #4a4a4a;
}

.value {
  background: #fbfbfb;
  padding: 10px;
  border-radius: 8px;
  color: #4a4a4a;
  border: 1px solid #efefef;
  min-height: 46px;
  width: 100%;
  margin-bottom: 10px;
  outline: none;

  &.empty-field {
    background: #ffe4e4;
    border-color: #b3322c;
  }
}

.section-title {
  font-size: 20px;
  color: #121212;
}

.success {
  color: #fff !important;
}

.action {
  height: 35px;
  padding-top: 3px;
  border-radius: 5px !important;
  font-weight: 500;
  box-shadow: none !important;

  span {
    vertical-align: middle;
  }
}

.textarea-container {
  width: 350px;
}

.textarea {
  width: 100%;
}

::v-deep(.textarea) {
  textarea {
    height: 100px;
    padding-top: 10px;
  }
}

.reject-status {
  height: auto !important;
  max-width: 400px;
}
</style>
