<template>
  <div class="clients management-page card-2">
    <span class="title">Mimic</span>
    <div class="mt-1 position-relative">
      <div class="d-flex align-items-center">
        <a :href="loginLink">{{ loginLinkText }}</a>
      </div>

      <div class="form-group mb-2">
        <label for="userEmail">Enter User Email:</label>
        <input type="text" class="form-control" id="userEmail" v-model="userEmail" required>
      </div>


      <button class="mt-4 w-100 btn btn-primary" type="submit" :disabled=isSubmitting @click="submitMimic">
        Submit Mimic
      </button>
    </div>
  </div>
</template>

<script setup>
  // New below
  import { computed, ref, onMounted } from 'vue';
  import { router } from '@/router';
  import { useStore } from 'vuex';
  import http from '@/services/http';
  import "bootstrap/dist/css/bootstrap.min.css";
  import "bootstrap";
  import { useToast } from "vue-toastification"
  import { PERMISSION_PRIMITIVES, PRIMARY_CLIENT_ID } from '@/config/constants';

  onMounted(() => {
  });

  const toast = useToast()
  let toastdefault = { position: "top-center",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false}

  // Get user data
  const store = useStore();
  const defaultClientid = store.getters.defaultClientid;

  const userHasPermission = computed(() => (
    !store.getters.merchantHasPermissions ||
    store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.PERMISSIONS)
  ));

  if (!userHasPermission.value && defaultClientid !== PRIMARY_CLIENT_ID) {
    toast.error('User does not have MIMIC permission.');

    router.push('/');
  }

  const loginLink = ref('#');
  const loginLinkText = ref('Enter a user to mimic in the email box below. If the result is valid, this link will become usable to mimic.');
  const userEmail = ref('');
  const isSubmitting = ref(false);

  const fixTheLinkPerEnvironment = (theLink) => {
    const parsedUrl = new URL(theLink);

    // Replace the origin with the current window's origin
    parsedUrl.protocol = window.location.protocol;
    parsedUrl.hostname = window.location.hostname;
    parsedUrl.port = window.location.port;

    return parsedUrl.toString();
  }

  const submitMimic = () => {
    isSubmitting.value = true;
    const data = {
      email: userEmail.value
    };
    http.post(`/api/loginlink`, data)
      .then(response => {
        console.log(response);
        console.log("Mimic response above");
          if (response.status === 200 && response.data.message) {
            isSubmitting.value = false;
              loginLinkText.value = "Click here to mimic!"
              const theLink = fixTheLinkPerEnvironment(response.data.message);
              loginLink.value = theLink;
              console.log(loginLink.value);
              console.log('Success', response);
              toast.success("Successfully generated mimic link!", toastdefault);
          } else {
            loginLinkText.value = "API Error"
              isSubmitting.value = false;
              toast.error("Failed to mimic");
              console.error('Failed to mimic');
          }
          })
          .catch(error => {
            isSubmitting.value = false;
            toast.error("Failed to mimic");
            console.error('An error occurred while mimicing:', error);
          });
  };

</script>

<style lang="scss" scoped>
.title {
  color: #383838;
  font-size: 24px;
}
.loader {
  z-index: 2;
}

.add-user {
  height: 40px;
  padding: 0px 30px;
}

.search-dropdown {
  min-width: 200px;
}

.settings-btn {
  height: 40px;
  padding-top: 7px !important;
  border-radius: 5px !important;

  span {
    vertical-align: middle;
  }
}

.settings-link {
  background-color: var(--c-primary);
  color: var(--c-white);
  border-radius: 5px;
  text-decoration: none;
  height: 40px;
  padding: 7px 12px 6px;
  display: inline-block;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7a540;
  }
}

.clients {
  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
.modal-header {
    background-color: #6C757D;
    color: white;
    border-bottom: none;
  }

  .modal-header .modal-title {
  font-size: 20px;
  font-weight: 700;
  }

  .modal-body {
  padding: 20px;
  }

  .modal-body h6 {
  font-size: 18px;
  font-weight: 500;
  }

  .modal-body p a {
  color: black;
  text-decoration: underline;
  }

  .modal-body .form-group label {
  font-weight: 600;
  }
</style>
