<template>
  <div class="credit-card" :class="{ 'hover-effect': clickable }">
    <img
      v-if="cardIssuer !== 'BANK'"
      :src="cardIssuerLogo"
      alt="Card Issuer Logo"
      class="card-logo"
    />
    <bank v-else style="text-align: center" class="card-logo" />
    <span class="card-number">···· {{ lastFourDigits }}</span>
    <span
      v-if="expirationDate && cardIssuer !== 'BANK'"
      class="expiration-date"
      >{{ expirationDate }}</span
    >
  </div>
</template>

<script>
import visa from 'payment-icons/min/flat/visa.svg';
import card from 'payment-icons/min/flat/default.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import Bank from 'vue-material-design-icons/Bank.vue';

export default {
  name: 'CardTableItem',
  components: {
    Bank
  },
  props: {
    cardIssuer: {
      type: String,
      required: true,
      validator: (value) =>
        ['VISA', 'MC', 'DISC', 'AMEX', 'BANK', 'CARD'].includes(value)
    },
    lastFourDigits: {
      type: String,
      required: true
    },
    expirationDate: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cardIssuerLogo() {
      switch (this.cardIssuer) {
        case 'VISA':
          return visa;
        case 'MC':
          return mastercard;
        case 'DISC':
          return discover;
        case 'AMEX':
          return amex;
        case 'BANK':
          return Bank;
        default:
          return card;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.credit-card {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  width: 300px;
}

.card-logo {
  width: 50px;
  height: 30px;
  margin-right: 10px;
}

.card-number {
  font-size: 18px;
  font-weight: bold;
}

.expiration-date {
  margin-left: auto;
  font-size: 14px;
  color: #888;
}

.hover-effect {
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
</style>
