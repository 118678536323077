import { useStore } from 'vuex';
import { store as importedStore } from '@/store'
import { PERMISSION_PRIMITIVES } from '@/config/constants';

/**
 * returns true when the transaction is a successful transaction and not a negative one
 * @param {Object} input
 * @param {ReportTransaction} input.txData
 */
export function shouldShowRefund2({ txData }) {
  try {
    let shouldShowInPrincipal = shouldShowRefund2Unsafe({ txData });

    let store = useStore() || importedStore;

    if (store.getters.merchantHasPermissions) {
      return shouldShowInPrincipal && store.getters.hasPermissionOnMerchant(PERMISSION_PRIMITIVES.TRANSACTION_REFUND, txData.client_id || txData.client?.id);
    }

    return shouldShowInPrincipal;
  } catch (ignored) {
    console.log('shouldShowRefund2Unsafe')
    return false
  }
}

function shouldShowRefund2Unsafe({ txData }) {
  let isSuccess = (
    // payment status
    !(`${txData?.payment_status}` in {'false': true, 'False': true}) &&
    // request status
    (txData?.request_status === true)
  );

  let negative = (txData?.type in {
    'void': true,
    'refund': true,
    'ach_refund': true,
  });

  return isSuccess && !negative;
}

/**
 * @param {Object} input
 * @param {ReportTransaction} input.txData
 * @param {import('vue').Ref<boolean> | { value: boolean }} input.hasPermissions
 * @param {import('vue').Ref<boolean> | { value: boolean }} input.userHasAchRefundPermission
 * @param {import('vue').Ref<Set<string>> | { value: Set<string> }} input.userHasAchRefundPermission_to_merchants
 * @param {import('vue').Ref<boolean> | { value: boolean }} input.userHasCreditCardRefundPermission
 * @param {import('vue').Ref<Set<string>> | { value: Set<string> }} input.userHasCreditCardRefundPermission_to_merchants
 * @returns {boolean}
 */
export function shouldShowRefund({
  txData,
  hasPermissions,
  userHasAchRefundPermission,
  userHasAchRefundPermission_to_merchants,
  userHasCreditCardRefundPermission,
  userHasCreditCardRefundPermission_to_merchants
}) {
  // fixed
  return shouldShowRefund2({txData});

  // noinspection EqualityComparisonWithCoercionJS
  if (
    (txData?.type !== 'refund' &&
      txData?.type !== 'void' &&
      txData?.type !== 'batch' &&
      txData?.type !== 'credit' &&
      txData?.type !== 'authorize' &&
      txData?.request_status === true &&
      txData?.payment_status !== false) ||
    txData?.payment_status != 'false' ||
    txData?.payment_status != 'False'
  ) {
    if (hasPermissions.value) {
      if (
        txData?.type === 'debit' &&
        userHasAchRefundPermission.value &&
        userHasAchRefundPermission_to_merchants.value?.has(txData.client_id)
      ) {
        return true;
      }

      // noinspection RedundantIfStatementJS
      if (
        (txData?.type === 'credit' ||
          txData?.type === 'cc' ||
          txData?.type === 'authorize') &&
        userHasCreditCardRefundPermission.value &&
        userHasCreditCardRefundPermission_to_merchants.value?.has(
          txData.client_id
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
  return false;
}
