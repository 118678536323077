<template>
  <ul
    id="billingTab"
    class="nav nav-tabs"
    role="tablist"
  >
    <li
      class="nav-item"
      role="presentation"
      style="background-color: #fff"
    >
      <router-link :to="`/reporting/classic`" class="nav-link">
        Classic
      </router-link>
    </li>
    <li class="nav-item" role="presentation" style="background-color: #fff">
      <router-link :to="`/reporting/search`" class="nav-link">
        Search
      </router-link>
    </li>
  </ul>

  <router-view />
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, watch } from 'vue';
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();
const store = useStore();

watch([() => route, () => store.state.user], () => {
  console.log('watched stuff');
  forwardIfNecessary();
});

onMounted(() => {
  console.log('mounted the reporting page page');
  forwardIfNecessary();
});

const REPORTING_URLS = {
  '/reporting/': true,
  '/reporting': true
};

function forwardIfNecessary() {
  if (route.path in REPORTING_URLS && store.state.user?.account) {
    let destPage =
      store.state.user.account?.default_client?.merchant?.configuration
        ?.customizations?.['default_search_page'] || 'classic';
    let dest = '/reporting/' + destPage;
    router.push(dest);
  }
}
</script>
<style>
/* Tabs */
.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.nav-item .nav-link {
  color: #495057;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 0 !important;
}

.nav-item .nav-link.active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 600;
  color: #007bff;
}
</style>
