<template>
  <div class="reporting management-page">
    <div class="title">Monthly Billing</div>

    <!-- Bootstrap Tabs -->
    <ul class="nav nav-tabs" id="billingTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="bill-details-tab" data-bs-toggle="tab" href="#bill-details" role="tab" aria-controls="bill-details" aria-selected="true">Billing</a>
      </li>
    </ul>

    <div class="tab-content" id="billingTabContent">
      <div class="tab-pane fade show active" id="bill-details" role="tabpanel" aria-labelledby="bill-details-tab">
        <ag-grid
            ref="agRef"
            :define-defs="lineItemColDefs"
            group-panel=""
            :excel-options="{ fileName: 'MonthlyBilling.xlsx' }"
            height="500px"
            :columns="[]"
            :side-bar="true"
        >
        </ag-grid>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import AgGrid from '@/components/AgGrid.vue'; // Assuming this is your AgGrid component
import http from '@/services/http';

// Data variables
const agRef = ref();
const agRefRolling = ref();
const anotherAgRef = ref();
const billDetailsData = ref([]);
const rollingBillingData = ref([]);
watch([rollingBillingData, agRefRolling], () => {
  if (!rollingBillingData.value)
    return
  agRefRolling.value?.setTheRowData(rollingBillingData.value)
})
const billDetailsTotal = ref(0);
const rollingBillDetailsTotal = ref(0);

// Fetch the API response and fill the tables
onMounted(async () => {
  try {

    // call the api
    const response = await http.get(`/api/monthly-billing`);
    billDetailsData.value = response.data.message;

    // update first table
    agRef?.value?.setTheRowData(billDetailsData.value);  // Set the row data
    agRef?.value?.setTheColumnDefs(columnDefinitionsBillDetails);  // Update column definitions

  } catch (error) {
    console.error('Failed to fetch data', error);
  }
});

// Define the column definitions for Bill Details table
const columnDefinitionsBillDetails = [
  { headerName: 'Merchant', field: 'name' },
  { headerName: 'Transaction Count', field: 'transaction_count' },
  { headerName: 'Monthly Minimum', field: 'monthly_minimum', valueFormatter: params => parseFloat(params.value).toFixed(2) },
  { headerName: 'Month', field: 'month', sort: 'desc' },
  { headerName: 'Is Transacting In Month', field: 'is_transacting_in_month' },
  { headerName: 'Total Transaction Amount', field: 'total_transaction_amount' },
  { headerName: 'Last Transaction Date In Month', field: 'last_transaction_date_in_month' },
];

const lineItemColDefs = computed(() => {
  return columnDefinitionsBillDetails
});

// Define the column definitions for Rolling Billing table
const columnDefinitionsRollingBilling = [
  { headerName: 'Merchant', field: 'name' },
  { headerName: 'Transaction Id', field: 'id' },
  { headerName: 'Processing Date', field: 'created_at' },
  { headerName: 'High Ticket Limit', field: 'limits_high_ticket', valueFormatter: params => parseFloat(params.value).toFixed(2) },
  {
    headerName: 'Amount',
    field: 'amount',
    valueFormatter: params => parseFloat(params.value).toFixed(2),
  },
  // { headerName: 'Total', field: 'total', valueFormatter: params => parseFloat(params.value).toFixed(2) }
];

const rollingColDefs = computed(() => {
  return columnDefinitionsRollingBilling
});

// Grid ready function
const onFirstDataRendered = (params) => {
  // doesn't work anymore
  console.log('onFirstDataRendered');
  params.api.sizeColumnsToFit();
  params.api.refreshCells({ force: true });
  params.api.resetState();
};

let rollingBillingHackRan = false;
function rollingBillingHack() {
  if (rollingBillingHackRan) return
  setTimeout(() => agRefRolling.value.resetState(), 100)
  setTimeout(() => agRef.value.resetState(), 100)
  rollingBillingHackRan = true;
}
</script>

<style lang="scss" scoped>
/* General page layout */
.management-page {
  padding: 20px;
  background-color: #f8f9fa; /* Lighter background for a cleaner look */
}

.title {
  color: #343a40;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Tabs */
.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.nav-item .nav-link {
  color: #495057;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 0 !important;
}

.nav-item .nav-link.active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 600;
  color: #007bff;
}

/* Table Container */
.table {
  margin-top: 20px;
  height: 60vh; /* Reduce height to give more space */
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background: #fff;
}

/* Totals Text */
.refresh-text {
  font-size: 18px;
  color: #495057;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}

/* Buttons */
.subs-btn {
  height: 40px;
  background: #007bff !important;
  border-color: #007bff !important;
  color: #fff !important;
  border-radius: 4px;
  transition: background 0.3s;

  &:hover {
    background: #0056b3 !important;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .management-page {
    padding: 10px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .nav-item .nav-link {
    font-size: 14px;
  }

  .table {
    height: 50vh;
  }
}

/* Custom styles for ag-grid */
.ag-theme-alpine {
  --ag-header-background-color: #f1f3f5;
  --ag-row-hover-color: #f8f9fa;
  --ag-header-cell-text-color: #343a40;
  --ag-odd-row-background-color: #fff;
  --ag-header-font-weight: 600;
}

</style>




<!--<template>-->
<!--  <div class="clients management-page">-->
<!--    <div class="mt-1 position-relative">-->
<!--      <div class="title" style="color: #383838; font-size: 24px;">Transactions 3x Over Standard Deviation</div>-->
<!--      <div class="risk-table">-->
<!--        <BaseSpinner v-if="monitoredTransactionsLoading" />-->
<!--        <table v-else class="table">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>Merchant</th>-->
<!--            <th>Transaction</th>-->
<!--            <th>Amount</th>-->
<!--            <th>Created At</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for="t in monitoredTransactions" :key="t.transaction_id">-->
<!--            <td>{{ t.merchant }}</td>-->
<!--            <td>{{ t.transaction_id }}</td>-->
<!--            <td>{{ t.amount }}</td>-->
<!--            <td>{{ formatDate(t.created_at) }}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script setup>-->
<!--import { ref, onMounted } from 'vue';-->
<!--import AgGrid from '@/components/AgGrid.vue'-->
<!--import BaseSpinner from "@/components/base/BaseSpinner.vue";-->
<!--import {router} from "@/router";-->
<!--import CardTableItem from "@/components/cards/CardTableItem.vue";-->
<!--import useService from "@/composables/common/services";-->
<!--const { apiService } = useService();-->
<!--import http from "@/services/http";-->

<!--const { DateTime } = require('luxon');-->

<!--const monitoredTransactionsLoading = ref(false);-->
<!--const monitoredTransactions = ref([]);-->

<!--// Date formatting function-->
<!--function formatDate(dateString) {-->
<!--  if (!dateString) return '';-->
<!--  const date = DateTime.fromISO(dateString);-->
<!--  return date.toFormat('yyyy-MM-dd HH:mm:ss');-->
<!--}-->

<!--async function fetchMonitoredTransactions() {-->
<!--  monitoredTransactionsLoading.value = true;-->
<!--  try {-->
<!--    const response = await apiService.get(`api/transaction-monitoring`);-->
<!--    monitoredTransactions.value = [...response.message.standard_deviation_list];-->
<!--  } catch (error) {-->
<!--    console.error('Error fetching risk information:', error);-->
<!--  } finally {-->
<!--    monitoredTransactionsLoading.value = false;-->
<!--  }-->
<!--}-->


<!--onMounted(() => {-->
<!--  fetchMonitoredTransactions();-->
<!--});-->

<!--const apiUrl = ref(`/api/transaction-monitoring`)-->
<!--const columnDefs = [-->
<!--    {-->
<!--      headerName: 'Merchant',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.merchant;},-->
<!--    },-->
<!--    {-->
<!--      headerName: 'Transaction',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.transaction_id;},-->
<!--    },-->
<!--    {-->
<!--      headerName: 'Amount',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.amount;},-->
<!--    },-->
<!--    {-->
<!--      headerName: 'Created At',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.created_at;},-->
<!--      sort:'desc',-->
<!--      valueFormatter: (params) => {-->
<!--        if (!params.value) {-->
<!--          return '';-->
<!--        }-->

<!--        const date = new Date(params.value);-->

<!--        const v = DateTime.fromJSDate(date);-->

<!--        return v.toFormat('yyyy-MM-dd hh:mm:ss');-->
<!--      }-->
<!--    }-->
<!--  ]-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--.loader {-->
<!--  z-index: 2;-->
<!--}-->
<!--</style>-->

<!--<style lang="scss">-->
<!--.clients {-->
<!--  .actions {-->
<!--    height: 100% !important;-->
<!--  }-->

<!--  .action-btn {-->
<!--    line-height: initial;-->
<!--    width: 30px !important;-->
<!--    height: 30px !important;-->
<!--    padding: 2px 1px !important;-->
<!--    font-size: 14px !important;-->
<!--    border-radius: 50% !important;-->

<!--    .icon-container {-->
<!--      width: auto !important;-->

<!--      svg {-->
<!--        width: 19px !important;-->
<!--      }-->
<!--    }-->

<!--    &.success {-->
<!--      color: #fff !important;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
