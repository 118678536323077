import Login from '@/pages/LoginPage.vue';
import VerifyEmail from '@/pages/VerifyEmail.vue';
import Sso from '@/pages/SingleSignOn.vue';
import Onboard from '@/components/OnboardComponent.vue';
import Account from '@/components/AccountComponent.vue';
import HomeDash from '@/components/HomeDash.vue';
import Reporting from '@/pages/ReportingPage.vue';
import ReportingPage from '@/pages/ReportingPagePage.vue';
import ReportingSearchPage from '@/pages/ReportingSearchPage.vue';
import AdminReportingWithSnowflake from '@/pages/AdminReportingPage.vue';
import AdminReporting from '@/pages/AdminReportingPagePage.vue';
import CertificationPage from '@/pages/CertificationPage.vue';
import CostingPage from '@/pages/CostingPage.vue';
import Statement from '@/pages/StatementPage.vue';
import Merchants from '@/pages/MerchantAccounts.vue';
import Billing from '@/pages/BillingPage.vue';
import Mimic from '@/pages/MimicPage.vue';
import MonthlyBilling from '@/pages/MonthlyBilling.vue';
import Clist from '@/pages/ClientList.vue';
import Olist from '@/pages/OnboardingList.vue';
import RevShareAdmin from '@/pages/RevShareAdmin.vue';
import CreateLink from '@/pages/CreateLink.vue';
import Users from '@/pages/UsersPage.vue';
import Unsubscribe from '@/pages/UnsubscribePage.vue';
import RecurCharges from '@/pages/RecurCharges.vue';
import RiskDashboard from '@/pages/RiskDashboard.vue';
import OperationsDashboard from '@/pages/OperationsDashboard.vue';
import RevShare from '@/pages/RevShare.vue';
import Terminals from '@/pages/TerminalsPage.vue';
import UploadClientDocuments from '@/pages/UploadClientDocuments.vue';
import UserUnsubscribe from '@/pages/UserUnsubscribe.vue';
import Payouts from '@/pages/PayoutPage.vue';
import VirtualTerminal from '@/pages/VirtualTerminal.vue';
import ConfigureTerminal from '@/pages/settings/ConfigureTerminal.vue';
import DevCertRegistration from '@/pages/DevCertRegistrationPage.vue';
import PermissionsPage from '@/pages/PermissionsPage.vue';
import SublocationsPage from '@/pages/SublocationsPage.vue';
import Vault from '@/pages/VaultPage.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import ManagementLayout from '@/layouts/ManagementLayout.vue';
import AllInternalDataReportingPage from '@/pages/AllInternalDataReportingPage.vue';
import EnrollPortalPage from "@/pages/EnrollPortalPage.vue";

/**
 * @type {Array<import("vue-router").RouteRecord>}
 */
const APP_ROUTES = [
  {
    name: 'Unsubscribe',
    path: '/unsubscribe',
    component: Unsubscribe,
    meta: {
      requiresRole: false
    }
  },
  {
    name: 'Dashboard',
    path: '/home',
    component: HomeDash,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent', 'healnow']
    }
  },
  {
    name: 'Home',
    path: '/',
    redirect: '/home'
  },
  // {
  //   name: 'Charge',
  //   path: '/charge',
  //   component: Charge,
  //   meta: {
  //     layout: ManagementLayout,
  //     // todo make sure athletico doesn't see (remove from admin, not super admin)
  //     roles: ['admin', 'user', 'parent']
  //   }
  // },
  {
    name: 'Virtual Terminal',
    path: '/virtual-terminal/:apikey',
    component: VirtualTerminal,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent']
    }
  },
  {
    name: 'Configure Terminal',
    path: '/configure-terminal/:apikey',
    component: ConfigureTerminal,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent']
    }
  },
  {
    name: 'Mimic',
    path: '/mimic',
    component: Mimic,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Monthly Billing',
    path: '/monthly-billing',
    component: MonthlyBilling,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Accounts',
    path: '/accounts',
    component: Clist,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Account',
    path: '/account/:clientid',
    component: Account,
    props: true,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Onboarding',
    path: '/onboarding',
    component: Olist,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'healnow']
    }
  },
  {
    name: 'Onboard',
    path: '/onboard/:id',
    component: Onboard,
    props: true,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'healnow', 'parent']
    }
  },
  {
    name: 'Merchant Accounts',
    path: '/merchants',
    component: Merchants,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Certification Page',
    path: '/certification-page',
    component: CertificationPage,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user']
    }
  },
  {
    name: 'Enroll Portal Page',
    path: '/enroll-portal-page',
    component: EnrollPortalPage,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user']
    }
  },
  {
    name: 'Permissions',
    path: '/permissions',
    component: PermissionsPage,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'alpha']
    }
  },
  {
    name: 'Sublocations',
    path: '/sublocations',
    component: SublocationsPage,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'alpha']
    }
  },
  {
    name: 'AdminReporting',
    path: '/admin-reporting',
    component: AdminReporting,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    },
    children: [
      {
        path: 'with-snowflake',
        component: AdminReportingWithSnowflake,
      },
      {
        path: 'all-internal/:sourceName',
        component: AllInternalDataReportingPage,
      },
    ]
  },
  {
    name: 'Payouts',
    path: '/payouts',
    component: Payouts,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent']
    }
  },
  {
    name: 'Billing',
    path: '/billing',
    component: Billing,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'CostingPage',
    path: '/costing',
    component: CostingPage,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Reporting',
    path: '/reporting',
    component: ReportingPage,
    // redirect: "/reporting/classic",
    children: [
      {
        path: 'classic',
        component: Reporting,
      },
      {
        path: 'search',
        component: ReportingSearchPage,
      },
    ],
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent']
    }
  },
  {
    name: 'Vault',
    path: '/vault',
    component: Vault,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent']
    }
  },
  {
    name: 'Statement',
    path: '/statement',
    component: Statement,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent']
    }
  },
  {
    name: 'Risk dashboard',
    path: '/risk',
    component: RiskDashboard,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Operations',
    path: '/operations',
    component: OperationsDashboard,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'healnow']
    }
  },
  {
    name: 'Rev Share',
    path: '/rev-share',
    component: RevShare,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Rev Share Admin',
    path: '/revshareadmin',
    component: RevShareAdmin,
    meta: {
      layout: ManagementLayout,
      roles: ['admin']
    }
  },
  {
    name: 'Recurring Charges',
    path: '/report/recurring',
    component: RecurCharges,
    meta: {
      layout: ManagementLayout,
      title: 'Recurring Charges',
      roles: ['user', 'admin', 'parent']
    }
  },
  {
    name: 'Users',
    path: '/users',
    component: Users,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent', 'healnow']
    }
  },
  {
    name: 'Development Certification Enrollment',
    path: '/certregistration',
    component: DevCertRegistration,
    meta: {
      roles: ['admin', 'parent']
    }
  },
  {
    name: 'Terminals',
    path: '/terminals',
    component: Terminals,
    meta: {
      layout: ManagementLayout,
      roles: ['admin', 'user', 'parent']
    }
  },
  {
    name: 'Subscription Unsubscribe',
    path: '/unsubscribe/:seriesId+:merchantName+:amount',
    component: UserUnsubscribe,
    props: true,
    meta: {}
  },
  {
    name: 'Upload client documents',
    path: '/documents/upload/:clientid',
    component: UploadClientDocuments,
    meta: {
      layout: ManagementLayout,
      showMenuByQuery: true
    }
  },
  {
    name: 'Create link',
    path: '/createlink/:apikey?',
    component: CreateLink,
    meta: {
      layout: ManagementLayout,
      showMenuByQuery: true,
      clientView: false,
      showSupportBtn: false
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      layout: AuthLayout,
      title: 'Login',
      guestOnly: true
    }
  },
  {
    name: 'Verify Email',
    path: '/verify-email',
    component: VerifyEmail,
    meta: {}
  },
  {
    name: 'Sso',
    path: '/sso',
    component: Sso,
    meta: {
      guestOnly: true
    }
  }
];

let routes = APP_ROUTES;

export default routes;
