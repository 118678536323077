<template>
  <div>
    <h1 class="fz-14 fw-600 text-uppercase">New note</h1>

    <base-spinner v-if="loading" />


        <base-input
          v-model="description"
          :error="descriptionError"
          placeholder="Description Here"
          class="mt-2"
          textarea
        />

    <base-button class="w-100 mt-3" @click="submit"> Save </base-button>
  </div>
</template>

<script>
import { useForm, useField } from 'vee-validate';
import useService from '@/composables/common/services';
import { useRoute } from 'vue-router';
import useToast from '@/composables/common/toast';
import useCustomApikey from '@/composables/user';
import { useStore } from 'vuex';
import * as yup from 'yup';
const { NOTE_CATEGORIES } = require('@/config/constants.js');
import { computed, ref, toRefs } from 'vue';
import { onMounted } from 'vue';

import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import { Mentionable } from 'vue-mention';

const validationSchema = yup.object().shape({
  description: yup.string().required('Field is required').nullable(),
  selectedClients: yup.array().min(1, 'Field is required').nullable()
});

export default {
  name: 'CreateNoteModal',
  components: {
    BaseInput,
    BaseButton,
    BaseDropdown,
    BaseSpinner,
    BaseCheckbox,
    Mentionable
  },
  props: {
    clientId: {
      type: String,
      default: null
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { apiService } = useService();
    const route = useRoute();
    const store = useStore();
    const { showMessage } = useToast();
    const { customApikey } = useCustomApikey();
    const { clientId } = toRefs(props);

    const { validate, resetForm } = useForm({
      validationSchema
    });

    const user = computed(() => {
      return store.state.user;
    });

    const loading = ref(false);
    const mentions = ref(null);
    const clients = ref([]);
    const admins = ref([]);
    const searchClient = ref('');

    const { value: selectedClients, errorMessage: clientError } = useField(
      'selectedClients',
      null,
      {
        validateOnValueUpdate: false,
        initialValue: []
      }
    );

    const { value: description, errorMessage: descriptionError } = useField(
      'description',
      null,
      {
        validateOnValueUpdate: false
      }
    );
    const category = ref(null);
    const catogoryError = ref(null);

    const filteredClients = computed(() => {
      return clients.value.filter((client) => {
        const v = searchClient.value.toLowerCase();
        const name = (client.text || '').toLowerCase();

        return name.includes(v);
      });
    });

    const usersToMention = computed(() => {
      return admins.value.map((el) => {
        return {
          value: el.email,
          label: `${el.name} ${el.email}`
        };
      });
    });

    const apiHandler = (res) => {
      return res
        .map((item) => {
          return {
            id: item[0],
            value: item[0],
            text: item[2].name,
            ...item[2]
          };
        })
        .filter((el) => el.name);
    };

    const postStatementNote = (noteDetails) => {
      // Check if the tag's value is 'statementNote'
      if (noteDetails.tag && noteDetails.tag.toLowerCase() === 'statementnote') {
        // Extract the relevant data
        const { text } = noteDetails;

        // Make an API call to post the data to /api/notes/statement
        apiService
          .post('api/notes/statement', {
            text
          })
          .then((res) => {
            // Handle the response if needed
            console.log('Note posted to /api/notes/statement successfully:', text, res);
          })
          .catch((error) => {
            // Handle API error, e.g., show an error message
            console.error('Error posting Statement Note:', error);
          });
      }
    };

    const mentionPeople = async (clientsList) => {
      const values = description.value.match(/@.+?(?=\s|$)/g) || [];

      if (!values.length) {
        return;
      }

      const withoutFirstAtValue = values.map((el) => el.slice(1, el.length));
      let emailTextStart = '';

      let clientDetails;
      if (clientsList.length === 1) {
        clientDetails = clients.value.find((e) => e.id === clientsList[0]);
        emailTextStart = `Hi, you were mentioned in note for client ${clientDetails.name}(${clientDetails.email} / ${clientDetails.id}).`;
      } else {
        clientDetails = clientsList.reduce((prev, curr) => {
          const details = clients.value.find((e) => e.id === curr);
          const value = prev ? `, ${details?.name}` : details?.name || '';

          return prev + value;
        }, '');

        emailTextStart = `Hi, you were mentioned in note for clients ${clientDetails}`;
      }

      const emailText = `${emailTextStart}
      <br />
      <br />
      <i>
        ${description.value}
      </i>
      <br />

      <a href="${window.location.origin}/app/clients?view_note=${clientsList[0]}">Click here to open note</a>

      <br />
      <br />
     `;

      for await (let email of withoutFirstAtValue) {
        await apiService.post('api/email', {
          subject: 'You were mentioned in note',
          body: emailText,
          to: email
        });
      }
    };

    const submit = async () => {
      if (clientId.value && (!description.value)) {
        catogoryError.value = 'Description is required';
        return;
      }

      loading.value = true;
      let proms = [];

      let selected;

        proms.push(
          apiService.post('api/notes', {
            comment: description.value,
            client_id: clientId.value,
            origin: 'Portal'
          })
        );

      Promise.all(proms)
        .then(async () => {
          loading.value = false;

          // Call postStatementNote with the necessary data
          postStatementNote({
            text: description.value,
            tag: category.value || route.meta.category
          });

          resetForm();

          category.value = null;
          selectedClients.value = [];
          showMessage({
            title: 'Success',
            text: 'Note was successfully created.',
            type: 'success'
          });

          emit('close');
        })
        .catch(() => {
          loading.value = false;

          showMessage({
            title: 'Error',
            text: 'Something went wrong.',
            type: 'danger'
          });
        });
    };

    const selectCheckbox = (value) => {
      const index = selectedClients.value.indexOf(value);

      if (index === -1) {
        selectedClients.value.push(value);
        return;
      }

      selectedClients.value.splice(index, 1);
    };

    const selectAll = () => {
      const filtered = filteredClients.value.map((e) => e.value) || [];

      if (selectedClients.value.length !== filtered.length) {
        selectedClients.value = filtered;
        return;
      }

      selectedClients.value = [];
    };

    const placeholderValue = computed(() => {
      if (!selectedClients.value.length) {
        return 'Select clients';
      }

      if (selectedClients.value.length === 1) {
        return 'Selected 1 client';
      }

      return `Selected ${selectedClients.value.length} clients`;
    });

    return {
      loading,
      // client,
      clientError,
      description,
      descriptionError,
      filteredClients,
      NOTE_CATEGORIES,
      category,
      catogoryError,
      usersToMention,
      mentions,
      clients,
      selectedClients,
      placeholderValue,

      submit,
      searchClient,
      selectCheckbox,
      selectAll
    };
  }
};
</script>

<style lang="scss" scoped>
.select-user-item {
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 5px;
  min-width: 200px;

  &:hover {
    background-color: var(--c-primary);
    color: var(--c-white);
  }
}

.no-result {
  border-radius: 5px;
  min-width: 200px;
}

.select-all {
  height: 25px;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 5px !important;
}
</style>

<style lang="scss">
</style>
