<template>
  <div>
    <div class="fz-14 fw-600 color-dark d-flex">{{ p.clientDetails.name || p.clientDetails.dba_name }}</div>
    <transition name="slide-y">
      <div v-if="showCreateNote" class="pt-4 px-2">
        <create-note :client-id="p.clientDetails.id" @close="handleNoteClose" />

        <div class="pb-2 pt-4">
          <hr class="hr" />
        </div>
      </div>
    </transition>

    <div class="mt-3">
      <transition-group name="slide-y" tag="ul" class="notes-list">
        <li v-for="note in filteredNotes" :key="note.id" class="note-item mb-2">
          <base-spinner v-if="note.id === idLoading" class="note-spinner" />
          <div class="d-flex justify-content-between">
            <!-- <note-tag :value="note.tag.toLowerCase()" /> -->

            <span class="date">
              {{ note.created_at }}
            </span>
          </div>

          <div class="note-description fz-14 mt-2 color-dark">
            {{ note.text }}
          </div>

          <!-- <base-tooltip title="Delete note" class="delete-note-tooltip">
            <span
              class="delete-note-btn d-flex align-items-center justify-content-center"
              @click="deleteNote(note.id)"
            >
              <delete :size="13" class="delete-btn-icon" />
            </span>
          </base-tooltip> -->
        </li>
      </transition-group>
    </div>

    <!-- <ul class="notes-list mt-3"></ul> -->

    <div
      v-if="!filteredNotes.length"
      class="text-center fz-14 fw-500 italic color-grey-medium mt-3"
    >
      No notes found
    </div>
  </div>
</template>

<script setup>
import { computed, ref, toRefs, defineProps } from 'vue';
import useService from '@/composables/common/services';
import useToast from '@/composables/common/toast';
import { onMounted } from 'vue';
const { DateTime } = require('luxon');
const { NOTE_CATEGORIES } = require('@/config/constants.js');
import NoteTag from '@/components/clients/NoteTag.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import Multiselect from '@vueform/multiselect';
import Delete from 'vue-material-design-icons/Delete.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import CreateNote from '@/components/general/CreateNote.vue';

  const p = defineProps( {
    clientid: {type:String},
    clientDetails: { type: Object},
    isEvents: { type: Boolean},
    initialTags: {type: Array, default: () => []}
  })
    const notes = ref([]);
    const search = ref('');
    const tagFilter = ref([]);
    const { apiService } = useService();
    const { showMessage } = useToast();
    const idLoading = ref(null);
    const showCreateNote = ref(false);
    const showAddNote = ref(false);

    const getEvents = () => {
      apiService
        .get(`api/notes?id=${p.clientid}&is_event=true`)
        .then((res) => {
          notes.value = res.message.reverse().map((el) => {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            return {
              id: el.id,
              tag: el.domain,
              text: el.data,
              created_at: DateTime
                  .fromISO(el.created_at, { zone: userTimeZone })
                  .toLocaleString(DateTime.DATETIME_MED),
            };
          });
        });
    };

    const filteredNotes = computed(() => {
      // const searchLowered = search.value.toLowerCase();
      // const trimmedTagFilter = tagFilter.value.map(tag => tag.toLowerCase().trim());
      return notes.value;
      // return notes.value.filter((n) => {
      //   if (trimmedTagFilter.length === 0) {
      //     return n.text.toLowerCase().includes(searchLowered);
      //   }

      //   const noteTagLowered = n.tag.toLowerCase();
      //   console.log("Note Tag:", noteTagLowered);

      //   return (
      //     n.text.toLowerCase().includes(searchLowered) &&
      //     trimmedTagFilter.includes(noteTagLowered)
      //   );
      // });
    });

  const getNotes = () => {
    showCreateNote.value = true;
    showAddNote.value = true;
    apiService
        .get(`api/notes?id=${p.clientid}`)
        .then((res) => {
          notes.value = res.message.reverse().map((el) => {
            console.log("Here is element in notes: ", el)
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            return {
              id: el.id,
              text: el.comment.comment,
              created_at: DateTime
                  .fromISO(el.created_at, { zone: userTimeZone })
                  .toLocaleString(DateTime.DATETIME_MED),
            };
          });
        });
  };

    const handleNoteClose = () => {
      showCreateNote.value = false;
      getNotes();
    };

    onMounted(() => {
      if (p.isEvents) {
        getEvents();
      } else {
        getNotes();
      }
      // tagFilter.value = p.initialTags.value || [];
      tagFilter.value = [];
    });
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>
.add-note-button {
  height: 35px;
  border-radius: 5px !important;
  padding: 0 15px;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

.notes-list {
  padding: 0;
  margin: 0;
}

.note-description {
  word-break: break-word;
}

.delete-note-tooltip {
  width: 25px;
  height: 25px;
  margin-left: auto;
  position: absolute;
  right: 15px;
  bottom: 0px;
}

.delete-note-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #e76565;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
  transform: translateX(20px) rotate(180deg);

  &:hover {
    background-color: #d15858;
  }

  &:active {
    background-color: #b74949;
  }

  .delete-btn-icon {
    height: 27px;
  }
}

.note-item {
  list-style: none;
  padding: 10px;
  margin: 0;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  position: relative;

  &:hover {
    .delete-note-btn {
      opacity: 1;
      transform: translateX(0px) rotate(0);
    }
  }
}

.note-spinner {
  z-index: 3;
}

.date {
  font-size: 12px;
  color: var(--c-grey-medium);
}

.search-input {
  width: 300px;
}

.tags-select {
  --ms-radius: 5px;
  --ms-bg: var(--c-grey-lighten);
  --ms-border-color: var(--c-grey-light);
  --ms-placeholder-color: var(--c-grey-medium);
  --ms-tag-bg: var(--c-primary);

  min-width: 250px;
  height: 35px;
  min-height: 35px;
}

::v-deep(.search-input) {
  .input {
    height: 35px !important;
    border-radius: 5px !important;
  }
}

.hr {
  background-color: #a2a2a2;
}
</style>
