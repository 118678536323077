<template>
  <div style="padding: 10px;">
    <!-- Tabs -->
    <ul class="nav nav-tabs" id="detailTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
            class="nav-link active"
            id="transactions-tab"
            data-bs-toggle="tab"
            data-bs-target="#transactions"
            type="button"
            role="tab"
            aria-controls="transactions"
            aria-selected="true"
        >
          Daily Transactions
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
            class="nav-link"
            id="fees-tab"
            data-bs-toggle="tab"
            data-bs-target="#fees"
            type="button"
            role="tab"
            aria-controls="fees"
            aria-selected="false"
        >
          Processor Fees
        </button>
      </li>
    </ul>

    <div class="tab-content" id="detailTabContent">
      <!-- Daily Transactions Tab -->
      <div
          class="tab-pane fade show active"
          id="transactions"
          role="tabpanel"
          aria-labelledby="transactions-tab"
      >
        <ag-grid-vue
            class="ag-theme-balham"
            style="width: 100%; height: 300px;"
            :columnDefs="transactionsColumnDefs"
            :rowData="dailyTransactions"
            :defaultColDef="defaultColDef"
        ></ag-grid-vue>
      </div>

      <!-- Processor Fees Tab -->
      <div
          class="tab-pane fade"
          id="fees"
          role="tabpanel"
          aria-labelledby="fees-tab"
      >
        <ag-grid-vue
            class="ag-theme-balham"
            style="width: 100%; height: 300px;"
            :columnDefs="feesColumnDefs"
            :rowData="processorFees"
            :defaultColDef="defaultColDef"
        ></ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';

// Props received from the parent grid
const props = defineProps(['data']);

// Data for grids
const dailyTransactions = ref([]);
const processorFees = ref([]);

// Column definitions
const defaultColDef = {
  flex: 1,
  resizable: true,
  sortable: true,
  filter: true,
};

const transactionsColumnDefs = [
  {
    headerName: 'Transaction Day',
    field: 'transaction_day',
    valueFormatter: (params) => {
      const date = new Date(params.value);
      return date.toLocaleDateString();
    },
    sort: 'desc',
  },
  {
    headerName: 'Total Transactions',
    field: 'total_transactions',
  },
  {
    headerName: 'Total Processed',
    field: 'total_processed',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
  },
  {
    headerName: 'Total Fees',
    field: 'total_fees',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
  },
  {
    headerName: 'Total Adjustments',
    field: 'total_adjustments',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
  },
  {
    headerName: 'Total Chargebacks',
    field: 'total_chargebacks',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
  },
];

const feesColumnDefs = [
  {
    headerName: 'Date',
    field: 'STATEMENT_DATE',
    valueFormatter: (params) => {
      const date = new Date(params.value);
      return date.toLocaleDateString();
    },
    sort: 'desc',
  },
  {
    headerName: 'Description',
    field: 'DETAIL_DESCRIPTION',
    width: 300
  },
  {
    headerName: 'Count',
    field: 'ITEM_COUNT',
    width: 85
  },
  {
    headerName: 'Volume',
    field: 'VOLUME',
    width: 100
  },
  {
    headerName: 'Rate 1',
    field: 'R_RATE1',
    width: 100
  },
  {
    headerName: 'Rate 2',
    field: 'R_RATE2',
    width: 100
  },
  {
    headerName: 'Total',
    field: 'R_TOTAL',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
    width: 100
  },
];

onMounted(() => {
  const data = props.params.data;
  dailyTransactions.value = data.daily_transactions || [];
  processorFees.value = data.processor_fees || [];
});
</script>

<style scoped>
/* Add any necessary styles here */
</style>